import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: rgb(38,123,254);
    background: linear-gradient(90deg, rgba(38,123,254,1) 0%, rgba(126,78,225,1) 35%, rgba(238,57,183,1) 100%);
  }
`;

export const Logo = styled.img`
  width: 210px;
  height: 93,5px;
`;