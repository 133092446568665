import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Alert,
  UncontrolledAlert,
} from 'reactstrap';
import swal from 'sweetalert';

import MUIDataTable from "mui-datatables";
import classnames from 'classnames';

import * as Yup from 'yup';

import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import { GlobalStyle } from './styles';

import DefaultNavbar from '../../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../../components/Cards/TasksNumbersCard';

import { FiUsers } from 'react-icons/fi';
import { BsPlusCircle, BsEnvelope, BsPhone, BsCheckSquare } from 'react-icons/bs';
import { MdTitle, MdAlternateEmail, MdWorkOutline } from 'react-icons/md';
import { RiLockPasswordLine, RiLockPasswordFill } from 'react-icons/ri';

import { User } from '../../../context/AuthContext';

interface SelectDataFormat {
  value: string;
  label: string;
}

const Users = () => {

  const { signOut, refreshToken } = useAuth();
  const navigate = useNavigate();

  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const inputPasswordRepeatRef = useRef<HTMLInputElement>(null);
  const inputFullNameRef = useRef<HTMLInputElement>(null);
  const inputPhoneRef = useRef<HTMLInputElement>(null);
  const inputUsernameRef = useRef<HTMLInputElement>(null);
  const inputIsActiveRef = useRef<HTMLInputElement>(null);
  const inputRedefinePasswordRef = useRef<HTMLInputElement>(null);
  const inputRedefinePasswordRepeatRef = useRef<HTMLInputElement>(null);
  
  const [users, setUsers] = useState<User[]>([{}] as User[]);

  const [modal, setModal] = useState(false);
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [username, setUsername] = useState<string | null>(null);

  const [activeTab, setActiveTab] = useState('1');

  const [dropdownIsAdminOpen, setDropdownIsAdminOpen] = useState(false);
  const [dropdownIsAdminValue, setDropdownIsAdminValue] = useState('Este usuário não é um administrador do sistema');
  const toggleDropdownIsAdmin = () => setDropdownIsAdminOpen(prevState => !prevState);

  const [popoverRedefinePasswordOpen, setPopoverRedefinePasswordOpen] = useState(false);
  const toggleRedefinePasswordPopover = () => setPopoverRedefinePasswordOpen(!popoverRedefinePasswordOpen);

  const toggleModal = () => {
    if (modal){
      setPopoverRedefinePasswordOpen(false);
    }
    setModal(!modal);
  }

  const getUsers = async() => {
    try{
      const response = await api.get('users');
      
      setUsers(response.data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
    }
  }
  
  useEffect(() => {

    getUsers();

  }, [signOut, refreshToken]);

  const toggle = (tab: string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const handleUsersRowClicked = async (rowData: string[]) => {
    setIsUpdate(true);

    const nickname = rowData[2];
    setUsername(nickname);

    try {
      const response = await api.get(`users/${nickname}`);
      toggleModal();
      
      inputEmailRef.current!.value = response.data.email;
      inputFullNameRef.current!.value = response.data.fullname;
      inputPhoneRef.current!.value = response.data.phone;
      inputUsernameRef.current!.value = response.data.username;
      inputIsActiveRef.current!.checked = response.data.is_active;

      if (response.data.is_admin){
        setDropdownIsAdminValue('Este usuário é um administrador do sistema');
      }else {
        setDropdownIsAdminValue('Este usuário não é um administrador do sistema');
      }

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      swal("Oppss!", err, "error");
    }
  }

  const handleNewUser = () => {
    setIsUpdate(false);
    toggleModal();
    setDropdownIsAdminValue('Este usuário não é um administrador do sistema');
  }

  const handleSubmit = async() => {

    setIsSaving(true);

    try {

      if (isUpdate) {

        const username = inputUsernameRef.current!.value;

        const data = {
          email: inputEmailRef.current!.value,
          fullname: inputFullNameRef.current!.value,
          phone: inputPhoneRef.current!.value,
          is_admin_label: dropdownIsAdminValue,
          is_admin: false,
          is_active: inputIsActiveRef.current!.checked
        }

        if (data.is_admin_label === 'Este usuário é um administrador do sistema'){
          data.is_admin = true;
        }

        const schema = Yup.object().shape({
          fullname: 
            Yup.string()
            .required('O nome completo do usuário precisa ser informado'),
          email: 
            Yup.string()
            .required('O e-mail não pode ficar em branco')
            .email('parece que este não é um e-mail válido'),
          phone: 
            Yup.string()
            .required('Um telefone precisa ser informado')
        });
        await schema.validate(data, {abortEarly: false} );

        try {
          const response = await api.put(`users/${username}`, data);
          toggleModal();
          swal("Tudo certo :)", "O usuário foi atualizado!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }else{

        const data = {
          email: inputEmailRef.current!.value,
          password: inputPasswordRef.current?.value,
          password_repeat: inputPasswordRepeatRef.current?.value,
          fullname: inputFullNameRef.current!.value,
          phone: inputPhoneRef.current!.value,
          username: inputUsernameRef.current!.value,
          is_admin_label: dropdownIsAdminValue,
          is_admin: false,
        }

        if (data.is_admin_label === 'Este usuário é um administrador do sistema'){
          data.is_admin = true;
        }

        const schema = Yup.object().shape({
          fullname: 
            Yup.string()
            .required('O nome completo do usuário precisa ser informado'),
          email: 
            Yup.string()
            .required('O e-mail não pode ficar em branco')
            .email('parece que este não é um e-mail válido'),
          phone: 
            Yup.string()
            .required('Um telefone precisa ser informado'),
          username: 
            Yup.string()
            .required('Um nome de usuário precisa ser especificado'),
          password: 
          Yup.string()
          .min(6, 'a senha precisa ter, no mínimo, 6 caracteres'),
          password_repeat: 
            Yup.string()
            .min(6, 'a confirmação da senha precisa ter, no mínimo, 6 caracteres e ser igual a senha')
        });
        await schema.validate(data, {abortEarly: false} );

        if (data.password !== data.password_repeat){
          swal("Oppss!", 'Parece que a senha a confirmação da senha não são iguais. Por favor, tente novamente.', "error");
          setIsSaving(false);
          return;
        }

        try {
          const response = await api.post('users', data);
          toggleModal();
          swal("Feito!", "O usuário foi incluído!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          swal("Oppss!", err, "error");
        }
      }
      
      setIsSaving(false);
  
      getUsers();
      
    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
      setIsSaving(false);
    }

  }

  const handleSubmitPasswordChange = async() => {

    const data = {
      password: inputRedefinePasswordRef.current?.value,
      password_repeat: inputRedefinePasswordRepeatRef.current?.value
    }

    try {

      const username = inputUsernameRef.current!.value;

      const schema = Yup.object().shape({
        password: 
        Yup.string()
        .min(6, 'a senha precisa ter, no mínimo, 6 caracteres'),
        password_repeat: 
          Yup.string()
          .min(6, 'a confirmação da senha precisa ter, no mínimo, 6 caracteres e ser igual a senha')
      });
      await schema.validate(data, {abortEarly: false} );

      if (data.password !== data.password_repeat){
        swal("Oppss!", 'Parece que a senha a confirmação da senha não são iguais. Por favor, tente novamente.', "error");
        setIsSaving(false);
        return;
      }
      
      const response = await api.put(`users/${username}`, data);
      toggleModal();
      swal("Tudo certo :)", "O usuário foi atualizado!", "success");

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch (err: any) {
      if (err.errors) {
        swal("Oppss!", err.errors[0], "error");
      }else {
        swal("Oppss! o servidor retornou o seguinte erro:", err, "error");
      }
    }
  }

  return (
    <React.Fragment>
      <GlobalStyle /> 
      <DefaultNavbar />
      <div data-users-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Usuário' : 'Adicionar Usuário' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col sm="12">
                  <Form role="form"> 
                    <Row>
                      <Col xs="12" sm="12">
                        <h6> <strong>Dados do usuário </strong> </h6>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="12" sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputFullName"
                              innerRef={inputFullNameRef} 
                              name="fullname" 
                              placeholder="Nome Completo" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col xs="6" md="6" sm="6">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <BsEnvelope/>
                            </InputGroupText>
                            <Input 
                              id="email"
                              innerRef={inputEmailRef} 
                              name="email" 
                              placeholder="Endereço de e-mail" 
                              type="email"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col xs="6" md="6" sm="6">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <BsPhone/>
                            </InputGroupText>
                            <Input 
                              id="inputPhone"
                              innerRef={inputPhoneRef} 
                              name="phone" 
                              placeholder="Número de telefone celular" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12" md="12" sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdAlternateEmail/>
                            </InputGroupText>
                            <Input 
                              id="inputUsername"
                              innerRef={inputUsernameRef}
                              disabled={isUpdate}
                              name="username" 
                              placeholder="Escolha o username (@) do usuário. Apenas letras minúsculas e sem espaços." 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                    </Row>
                    
                    {
                      isUpdate
                      ? null
                      : 
                      <>

                        <Row>
                          <Col xs="12" sm="12">
                            <h6> <strong>Senha </strong> </h6>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="6" md="6" sm="6">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <RiLockPasswordLine/>
                                </InputGroupText>
                                <Input 
                                  id="inputPassword"
                                  innerRef={inputPasswordRef} 
                                  name="password" 
                                  placeholder="Senha inicial" 
                                  type="password"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col xs="6" md="6" sm="6">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <RiLockPasswordFill/>
                                </InputGroupText>
                                <Input 
                                  id="inputPasswordRepeat"
                                  innerRef={inputPasswordRepeatRef} 
                                  name="passwordRepeat" 
                                  placeholder="Confirme a senha" 
                                  type="password"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                      </>
                    }

                    <Row>
                      <Col xs="12" sm="12">
                        <h6> <strong>Permissões </strong> </h6>
                      </Col>

                      <Col xs="12" sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                          <Dropdown isOpen={dropdownIsAdminOpen} toggle={toggleDropdownIsAdmin}>
                            <DropdownToggle caret>
                              { dropdownIsAdminValue === 'Este usuário é um administrador do sistema' ? <BsCheckSquare className="text-success"/> : undefined }
                              { dropdownIsAdminValue === 'Este usuário não é um administrador do sistema' ? <BsCheckSquare className="text-danger"/> : undefined }
                              &nbsp;{ dropdownIsAdminValue }
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className="text-muted" 
                                header
                              >
                                É um administrador do sistema?
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => { setDropdownIsAdminValue('Este usuário é um administrador do sistema') }}
                              >
                                <BsCheckSquare className="text-success"/>Este usuário é um administrador do sistema
                              </DropdownItem>
                              <DropdownItem 
                                onClick={() => { setDropdownIsAdminValue('Este usuário não é um administrador do sistema') }}
                              >
                                <BsCheckSquare className="text-danger"/>Este usuário não é um administrador do sistema
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col xs="12" sm="12">
                        {
                          isUpdate
                          ? 
                          <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Label check>
                                <Input
                                  type="checkbox"
                                  innerRef={inputIsActiveRef}
                                />{' '}
                                <strong>Usuário habilitado?</strong> (desabilita-lo o impede de realizar login)
                              </Label>
                            </InputGroup>
                          </FormGroup>
                          : null
                        }

                        {
                          isUpdate
                          ? 
                          <>
                            <Button id="PopoverRedefinePassword" color="link" type="button">
                              Redefinir senha?
                            </Button>
                            <Popover placement="auto" isOpen={popoverRedefinePasswordOpen} target="PopoverRedefinePassword" toggle={toggleRedefinePasswordPopover}>
                              <PopoverHeader>Alterar senha 
                                <a onClick={toggleRedefinePasswordPopover}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                x
                                </a>
                              </PopoverHeader>
                              <PopoverBody>

                                <UncontrolledAlert color="warning">
                                  Sua nova senha também precisa ter pelo menos seis caracteres.
                                </UncontrolledAlert>

                                <Form role="form">
                                  <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupText addonType="prepend">
                                        <RiLockPasswordLine/>
                                      </InputGroupText>
                                      <Input 
                                        id="inputRedefinePassword"
                                        innerRef={inputRedefinePasswordRef} 
                                        name="item" 
                                        placeholder="Digite a nova senha" 
                                        type="password"
                                      />
                                    </InputGroup>
                                  </FormGroup>

                                  <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupText addonType="prepend">
                                        <RiLockPasswordFill/>
                                      </InputGroupText>
                                      <Input 
                                        id="inputRedefinePasswordRepeat"
                                        innerRef={inputRedefinePasswordRepeatRef} 
                                        name="item" 
                                        placeholder="Confirme a nova senha" 
                                        type="password"
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </Form>

                                <br/>
                                <Button size="sm" onClick={handleSubmitPasswordChange} color="default" type="button">
                                  Atualizar senha
                                </Button>
                              </PopoverBody>
                            </Popover>
                          </>
                          : null
                        }
                      </Col>
                    </Row>

                  </Form>
                </Col>
              </Row>    
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="default" disabled={isSaving} onClick={handleSubmit}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
              <MUIDataTable
                title={"Usuários"}
                data={users}
                columns={
                  [
                    {
                      name: "id",
                      label: "id",
                      options: {
                        filter: false,
                        display: "excluded"
                      }
                    },
                    {
                      name: "show_name",
                      label: "Nome",
                      options: {
                        filter: false,
                        sort: true
                      }
                    },
                    {
                      name: "username",
                      label: "Nome de usuário",
                      options: {
                       filter: false,
                       sort: false
                      }
                    }
                  ]
                }
                options={
                  {
                    selectableRows: 'none',
                    onRowClick: (rowData: string[]) => {handleUsersRowClicked(rowData)},
                    textLabels: {
                      body: {
                        noMatch: "Desculpe, nenhum registro encontrado",
                        toolTip: "Ordernar",
                        columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                      },
                      pagination: {
                        next: "Próxima Página",
                        previous: "Página Anterior",
                        rowsPerPage: "Registros por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Buscar",
                        downloadCsv: "Baixar CSV",
                        print: "Imprimir",
                        viewColumns: "Visualizar Colunas",
                        filterTable: "Filtrar Tabela",
                      },
                      filter: {
                        all: "Tudo",
                        title: "FILTROS",
                        reset: "LIMPAR",
                      },
                      viewColumns: {
                        title: "Exibir Colunas",
                        titleAria: "Exibir/Ocultar Colunas da Tabela",
                      },
                      selectedRows: {
                        text: "registro(s) selecionados",
                        delete: "Apagar",
                        deleteAria: "Apagar registros selecionados",
                      },
                    }
                  }
                }
              />
              </Col>
              <Col lg="3" xl="3">
                <Button color="default" onClick={handleNewUser}>
                  <BsPlusCircle/> Incluir Usuário
                </Button>
                <br/><br/>
                <TasksNumbersCard
                  onClick={() => { navigate('/meu-perfil') }}
                  title="Usuários" 
                  description="Usuários cadastrados no sistema"
                  icon={FiUsers} 
                  circleColor="indigo"
                  tasksQuantity={ users.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default Users;