import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
  Tooltip
} from 'reactstrap';
import swal from 'sweetalert';
import MUIDataTable from "mui-datatables";
import Select, { ActionMeta, components, MultiValue, MultiValueProps, SingleValue, SingleValueProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import classnames from 'classnames';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import useAuth from '../../hooks/useAuth';
import api from '../../services/api';
import { GlobalStyle, ZoneContainer, CustomLabel, QuestionTitle, QuestionTitleBold, MiniModal, StyledDataTable } from './styles';

import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../components/Footers/GradientFooter';
import TasksNumbersCard from '../../components/Cards/TasksNumbersCard';
import AnimationContainer from '../../components/Containers/AnimationContainer';

import { GoTasklist, GoListOrdered } from 'react-icons/go';
import { FaCity, FaPercentage, FaArrowRight } from 'react-icons/fa';
import { GiFarmTractor } from 'react-icons/gi';
import { BsPlusCircle } from 'react-icons/bs';
import { MdTitle } from 'react-icons/md';

import { ResearchAgent } from '../ResearchAgents';

interface Research {
  id: string;
  title: string;
  uf: string;
  city: string;
  reference_year: string;
  reliability: number;
  margin_of_error: number;
  is_active: boolean;
  is_completed: boolean;
  completed_at: Date;
  questions: ResearchQuestion[];
  agents: ResearchAgent[];
  zones: ResearchZone[];
  created_at: Date;
  updated_at: Date;
}

interface ResearchQuestion {
  id: string;
  question: string;
  question_order: number;
  is_multiple: boolean;
  allow_spontaneous_response: boolean;
  avaliableAnswers: AvailableAnswer[];
  research: Research;
  created_at: Date;
  updated_at: Date;
}

interface ResearchZone {
  id: string;
  zone_name: string;
  zone_type: string;
  created_at: Date;
  updated_at: Date;
}

interface AvailableAnswer {
  id: string;
  answer: string;
  researchQuestion: ResearchQuestion;
  is_custom: boolean;
  created_at: Date;
  updated_at: Date;
}

interface SelectDataFormat {
  value: string;
  label: string;
}

interface InterviewQuestion {
  id: string;
  question: string;
  question_order: number;
  is_multiple: boolean;
  allow_spontaneous_response: boolean;
  avaliableAnswers: InterviewAnswer[];
  research: Research;
  created_at: Date;
  updated_at: Date;
}

interface InterviewAnswer {
  id: string;
  answer: string;
  selected?: boolean;
  is_custom: boolean;
  created_at: Date;
  updated_at: Date;
}

interface IBGEUFResponse {
  sigla: string;
}

interface IBGECityResponse {
  nome: string;
}

interface VoterData {
  state: string;
  city: string;
  gender_distribution: Record<string, number>;
  education_levels: Record<string, number>;
  age_ranges: Record<string, number>;
  total_electorate: number;
  total_with_biometry: number;
  total_with_social_name: number;
  total_with_disability: number;
  reference_year: string;
}

type NormalizationRule = {
  id: string;
  original_text: string;
  normalized_text: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date | null;
  research_question_id: string;
}

const Researches = () => {

  const { signOut, refreshToken, user } = useAuth();
  const navigate = useNavigate();

  const inputResearchTitleRef = useRef<HTMLInputElement>(null);
  const inputIsActiveRef = useRef<HTMLInputElement>(null);
  const inputIsCompletedRef = useRef<HTMLInputElement>(null);
  const inputMarginOfErrorRef = useRef<HTMLInputElement>(null);

  const inputResearchQuestionRef = useRef<HTMLInputElement>(null);
  const inputQuestionIsMultipleRef = useRef<HTMLInputElement>(null);
  const InputAllowSpontaneousResponseRef = useRef<HTMLInputElement>(null);
  const inputResearchQuestionOrderRef = useRef<HTMLInputElement>(null);
  const inputAnswerOrderRef = useRef<HTMLInputElement>(null);
  const inputCustomAnswerRef = useRef<HTMLInputElement>(null);
  
  const inputAnswerRef = useRef<HTMLInputElement>(null);

  const inputZoneNameRef = useRef<HTMLInputElement>(null);  

  const inputNormalizeOriginalTextRef = useRef<HTMLInputElement>(null);
  const inputNormalizeNormalizedTextRef = useRef<HTMLInputElement>(null);

  const [activeResearch, setActiveResearch] = useState<Research | null>(null);
  const [researches, setResearches] = useState<Research[]>([{}] as Research[]);

  const [voters, setVoters] = useState<VoterData | null>(null);
  const [recommendedSample, setRecommendedSample] = useState<number | null>(null);
  
  const [radioItems, setRadioItems] = useState<InterviewQuestion[]>([{}] as InterviewQuestion[]);

  const [researchQuestions, setResearchQuestions] = useState<ResearchQuestion[]>([{}] as ResearchQuestion[]);
  const [researchZones, setResearchZones] = useState<ResearchZone[]>([{}] as ResearchZone[]);

  const [availableAnswers, setAvailableAnswers] = useState<AvailableAnswer[]>([{}] as AvailableAnswer[]);

  const [normalizationRules, setNormalizationRules] = useState<NormalizationRule[]>([{}] as NormalizationRule[]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalResearchQuestion, setModalResearchQuestion] = useState(false);
  const toggleModalResearchQuestion = () => setModalResearchQuestion(!modalResearchQuestion);

  const [modalResearchZone, setModalResearchZone] = useState(false);
  const toggleModalResearchZone = () => setModalResearchZone(!modalResearchZone);

  const [modalCustomAnswerOption, setModalCustomAnswerOption] = useState(false);
  const toggleModalCustomAnswerOption = () => setModalCustomAnswerOption(!modalCustomAnswerOption);

  const [modalAvailableAnswer, setModalAvailableAnswer] = useState(false);
  const toggleModalAvailableAnswer = () => setModalAvailableAnswer(!modalAvailableAnswer);

  const [modalNormalization, setModalNormalization] = useState(false);
  const toggleModalNormalization = () => setModalNormalization(!modalNormalization);
  
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isUpdateResearchQuestion, setIsUpdateResearchQuestion] = useState(false);
  const [isSavingResearchQuestion, setIsSavingResearchQuestion] = useState(false);

  const [isUpdateResearchZone, setIsUpdateResearchZone] = useState(false);
  const [isSavingResearchZone, setIsSavingResearchZone] = useState(false);

  const [isUpdateAvailableAnswer, setIsUpdateAvailableAnswer] = useState(false);
  const [isSavingAvailableAnswer, setIsSavingAvailableAnswer] = useState(false);

  const [isUpdateNormalization, setIsUpdateNormalization] = useState(false);
  const [isSavingNormalization, setIsSavingNormalization] = useState(false);

  const [isSavingCustomAnswerOption, setIsSavingCustomAnswerOption] = useState(false);

  const [researchId, setResearchId] = useState<string | null>(null);
  const [researchZoneId, setResearchZoneId] = useState<string | null>(null);
  const [researchQuestionId, setResearchQuestionId] = useState<string | null>(null);
  const [availableAnswerId, setAvailableAnswerId] = useState<string | null>(null);
  const [normalizationId, setNormalizationId] = useState<string | null>(null);

  const [customAnswerforQuestionId, setCustomAnswerforQuestionId] = useState<string | null>(null);

  const [activeTab, setActiveTab] = useState('1');
  const [activeTabResearchQuestion, setActiveTabResearchQuestion] = useState('1');

  const [researchAgents, setResearchAgents] = useState<SelectDataFormat[]>([] as SelectDataFormat[]);
  const [selectedAgentsInResearch, setSelectedAgentsInResearch] = useState<SelectDataFormat[] | null>(null);

  const [dropdownZoneTypeOpen, setDropdownZoneTypeOpen] = useState(false);
  const [dropdownZoneTypeValue, setDropdownZoneTypeValue] = useState('urbana');
  const toggleDropdownZoneType = () => setDropdownZoneTypeOpen(prevState => !prevState);

  const [ufs, setUfs] = useState<SelectDataFormat[]>([{}] as SelectDataFormat[]);
  const [selectedUf, setSelectedUf] = useState<SelectDataFormat | null>(null);

  const [cities, setCities] = useState<SelectDataFormat[]>([{}] as SelectDataFormat[]);
  const [selectedCity, setSelectedCity] = useState<SelectDataFormat | null>(null);
  
  const [referenceYears, setReferenceYears] = useState<SelectDataFormat[]>([
    { value: "2022", label: "ref. eleições 2022" }
  ] as SelectDataFormat[]);
  const [selectedReferenceYear, setSelectedReferenceYear] = useState<SelectDataFormat>({ value: "2022", label: "ref. eleições 2022" } as SelectDataFormat);

  const [reliabilities, setReliabilities] = useState<SelectDataFormat[]>([
    { value: "90", label: "90%" },
    { value: "95", label: "95%" },
    { value: "99", label: "99%" }
  ] as SelectDataFormat[]);
  const [selectedReliability, setSelectedReliability] = useState<SelectDataFormat>({ value: "95", label: "95%" } as SelectDataFormat);

  const [marginOfErrorValue, setMarginOfErrorValue] = useState<string>('');

  const [tooltipWhatIsNormalizationOpen, setTooltipWhatIsNormalizationOpen] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);

  const animatedComponents = makeAnimated();

  const calculateSample = () => {
    if (!voters || !marginOfErrorValue) {
      setRecommendedSample(null);
      return;
    }
  
    const N = voters.total_electorate;
    const zValues: Record<string, number> = {
      "90": 1.645, // Valor z para 90% de confiança
      "95": 1.96,  // Valor z para 95% de confiança
      "99": 2.576  // Valor z para 99% de confiança
    };
  
    // Verifica se o valor de confiabilidade selecionado está dentro das chaves permitidas
    const z = zValues[selectedReliability.value];
    if (z === undefined) {
      setRecommendedSample(null);
      return;
    }
  
    const d = parseFloat(marginOfErrorValue) / 100;
    if (isNaN(d) || d <= 0) {
      setRecommendedSample(null);
      return;
    }
  
    const p = 0.5; // Assumindo p = 0.5 para máxima variação
    const numerator = N * z * z * p * (1 - p);
    const denominator = d * d * (N - 1) + z * z * p * (1 - p);
  
    const sampleSize = Math.round(numerator / denominator);
  
    setRecommendedSample(sampleSize);
  };    

  const getTSEData = async() => {
    if (selectedUf && selectedCity && selectedReferenceYear) {
      try {
        const response = await api.get(`voters/${selectedUf.value}/${selectedCity.value}/${selectedReferenceYear.value}`);

        setVoters(response.data);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      }catch(err: any) {
        if (err.response && err.response.status === 401) {
          signOut(navigate);
        }
        swal("Oppss!", `Erro ao obter dados do eleitorado: ${err}`, "error");
      }
    }
  }

  const getResearches = async() => {
    try{
      const response = await api.get('researches');

      setResearches(response.data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

      setLoading(false);

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
      setLoading(false);
    }
  }
  
  useEffect(() => {

    getAgents();
    getResearches();

  }, 
  [
    signOut, 
    refreshToken, 
    researchId, 
    researchQuestionId, 
    availableAnswerId,
    normalizationId, 
    researchZoneId,
    activeResearch
  ]);

  useEffect(() => {
    getTSEData();
  }, 
  [
    selectedUf, 
    selectedCity,
    selectedReferenceYear
  ]);

  useEffect(() => {
    calculateSample();
  }, 
  [
    selectedUf, 
    selectedCity,
    selectedReferenceYear,
    selectedReliability,
    marginOfErrorValue,
    voters
  ]);

  const clearSimulation = () => {
    setRadioItems([]);
    getActiveResearch(researchId!);
  }

  const handleChangeSelectedAgentsInResearch = (
    newValue: SingleValue<SelectDataFormat> | MultiValue<SelectDataFormat>,
    actionMeta: ActionMeta<SelectDataFormat>
  ) => {
    if (Array.isArray(newValue)) {
      const multiValue = newValue as MultiValue<SelectDataFormat>;
      const selectedOptions = multiValue.map(option => option);
      setSelectedAgentsInResearch(selectedOptions);
    }
  };  

  const toggleTabResearch = (tab: string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const toggleTabResearchQuestion = (tab: string) => {
    if(activeTabResearchQuestion !== tab) setActiveTabResearchQuestion(tab);
  }

  const handleAvaliableAnswerRowClicked = async (id: string) => {
    toggleModalAvailableAnswer();
    setIsUpdateAvailableAnswer(true);
    setAvailableAnswerId(id);

    try {
      const response = await api.get(`available-answers/${id}`);
      
      inputAnswerRef.current!.value = response.data.answer;
      inputAnswerOrderRef.current!.value = response.data.answer_order;

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }

  }

  const handleNormalizationRulesRowClicked = async (id: string) => {
    toggleModalNormalization();
    setIsUpdateNormalization(true);
    setNormalizationId(id);

    try {
      const response = await api.get(`normalization-rules/${id}`);
      
      inputNormalizeOriginalTextRef.current!.value = response.data.original_text;
      inputNormalizeNormalizedTextRef.current!.value = response.data.normalized_text;

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }

  }

  const handleNewCustomAnswerOption = async (questionId: string) => {
    setCustomAnswerforQuestionId(questionId);
    toggleModalCustomAnswerOption();
  }

  const handleSubmitCustomAnswerOption = async () => {

    setIsSavingCustomAnswerOption(true);

    try {
      const data = {
        id: uuidv4(),
        answer: inputCustomAnswerRef.current!.value,
        researchQuestion: customAnswerforQuestionId!,
        is_custom: true,
        selected: false,
        created_at: new Date(),
        updated_at: new Date()
      }

      setRadioItems(current => {
        const questionIndex = current.findIndex(item => item.id === data.researchQuestion);
        
        if (questionIndex === -1) {
          return current;
        }

        if (current[questionIndex].allow_spontaneous_response) {
          const newState = [...current];
          const newAvailableAnswers = [...newState[questionIndex].avaliableAnswers, data];
          newState[questionIndex] = {
            ...newState[questionIndex],
            avaliableAnswers: newAvailableAnswers
          };
          return newState;
        }

        return current;
      });
      
    } catch (error) {
      swal("Oppss!", `Erro: ${error}`, "error");
    }

    setIsSavingCustomAnswerOption(false);
    toggleModalCustomAnswerOption();
    setCustomAnswerforQuestionId(null);

  }

  const handleResearchZonesRowClicked = async (id: string) => {

    toggleModalResearchZone(); 
    setIsUpdateResearchZone(true);
    setResearchZoneId(id);

    try {
      const response = await api.get(`research-zones/${id}`);
      
      inputZoneNameRef.current!.value = response.data.zone_name;
      setDropdownZoneTypeValue(response.data.zone_type);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }
  }

  const handleResearchQuestionsRowClicked = async (id: string, newWindow: boolean) => {

    if (newWindow === true) {
      toggleModalResearchQuestion(); 
      setActiveTabResearchQuestion('1');
    }else{
      setActiveTabResearchQuestion('2');
    }

    setIsUpdateResearchQuestion(true);
    setResearchQuestionId(id);
    getAvailableAnswers(id);
    getNormalizationRules(id);

    try {
      const response = await api.get(`research-questions/${id}`);
      
      inputResearchQuestionRef.current!.value = response.data.question;
      inputResearchQuestionOrderRef.current!.value = response.data.question_order;
      inputQuestionIsMultipleRef.current!.checked = response.data.is_multiple;
      InputAllowSpontaneousResponseRef.current!.checked = response.data.allow_spontaneous_response;

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }

  }

  const handleResearchesRowClicked = async (id: string, newWindow: boolean) => {

    setLoading(true);

    try {
      const response = await api.get(`researches/${id}`);

      setVoters(null);
      setRecommendedSample(null);

      if (newWindow === true) {
        toggleModal();
        setActiveTab('1');
      }else{
        setActiveTab('2');
      }

      setIsUpdate(true);
      setResearchId(id);
      
      await getActiveResearch(id);

      setLoading(false);
      
      inputResearchTitleRef.current!.value = response.data.title;
      inputIsActiveRef.current!.checked = response.data.is_active;
      inputIsCompletedRef.current!.checked = response.data.is_completed;

      setSelectedCity({label: response.data.city, value: response.data.city});
      setSelectedUf({label: response.data.uf, value: response.data.uf});

      if (response.data.reference_year === '2022') {
        setSelectedReferenceYear({ value: "2022", label: "ref. eleições 2022" });
      }

      const reliability = Math.floor(response.data.reliability);
      switch (reliability) {
        case 90:
          setSelectedReliability({ value: "90", label: "90%" });
          break;
        case 95:
          setSelectedReliability({ value: "95", label: "95%" });
          break;
        case 99:
          setSelectedReliability({ value: "99", label: "99%" });
          break;
        default:
          setSelectedReliability({ value: "95", label: "95%" });
          break;
      }

      const margin_of_error = response.data.margin_of_error;
      if (margin_of_error) {
        setMarginOfErrorValue(margin_of_error);
      } else {
        setMarginOfErrorValue('');
      }

      const agents = response.data.agents;
      const dataForAgentsInResearch: SelectDataFormat[] = [];
      agents.map((agent: ResearchAgent) => {
        dataForAgentsInResearch.push( {value: agent.id, label: `@${agent.username}`} );
      });
      setSelectedAgentsInResearch(dataForAgentsInResearch);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    }catch(err: any) {
      setLoading(false);
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }
  }

  const handleNewResearch = () => {
    setActiveResearch(null);
    setSelectedUf(null);
    setSelectedCity(null);
    setSelectedReferenceYear({ value: "2022", label: "ref. eleições 2022" });
    setSelectedReliability({ value: "95", label: "95%" });
    setMarginOfErrorValue('');
    setVoters(null);
    setRecommendedSample(null);
    setSelectedAgentsInResearch(null);
    setResearchQuestions([]);
    setResearchZones([]);
    setAvailableAnswers([]);
    setNormalizationRules([]);
    setIsUpdate(false);
    toggleModal();
    setActiveTab('1');
  }

  const handleNewResearchQuestion = () => {
    setAvailableAnswers([]);
    setNormalizationRules([]);
    setIsUpdateResearchQuestion(false);
    setActiveTabResearchQuestion('1');
    toggleModalResearchQuestion();
  }

  const handleNewResearchZone = () => {
    setIsUpdateResearchZone(false);
    toggleModalResearchZone();
  }

  const handleNewAvailableAnswer = () => {
    setIsUpdateAvailableAnswer(false);
    toggleModalAvailableAnswer();
  }

  const handleNewNormalization = () => {
    setIsUpdateNormalization(false);
    toggleModalNormalization();
  }

  const handleSubmitAvailableAnswer = async() => {

    setIsSavingAvailableAnswer(true);

    const data = {
      answer: inputAnswerRef.current!.value,
      answer_order: inputAnswerOrderRef.current!.value,
      question: researchQuestionId,
      is_custom: false
    }

    try {

      const schema = Yup.object().shape({
        answer:
          Yup.string()
          .required('Um texto de possível resposta precisa informado.'),
        answer_order:
          Yup.number()
          .required('Um número para definir a ordem da possível resposta precisa ser informada.').min(1, 'A ordem não pode ser menor que 1.')
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdateAvailableAnswer) {
        try {
          const response = await api.put(`available-answers/${availableAnswerId}`, data);
          toggleModalAvailableAnswer();
          swal("Tudo certo :)", "A possível resposta foi atualizada!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }else{
        try {
          const response = await api.post('available-answers', data);
          toggleModalAvailableAnswer();
          swal("Feito!", "A possível resposta foi incluída! :)", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }

      setIsSavingAvailableAnswer(false);
      getAvailableAnswers(researchQuestionId!);
      getNormalizationRules(researchQuestionId!);
      getActiveResearch(researchId!);
      
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
      setIsSavingAvailableAnswer(false);
    }

  }

  const handleSubmitNormalization = async() => {

    setIsSavingNormalization(true);

    const data = {
      original_text: inputNormalizeOriginalTextRef.current!.value,
      normalized_text: inputNormalizeNormalizedTextRef.current!.value,
      research_question_id: researchQuestionId,
    }

    try {

      const schema = Yup.object().shape({
        original_text:
          Yup.string()
          .required('Um texto de entrada precisa ser informado.'),
        normalized_text:
          Yup.string()
          .required('Um texto de saída precisa ser informado.')
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdateNormalization) {
        try {
          const response = await api.put(`normalization-rules/${normalizationId}`, data);
          toggleModalNormalization();
          swal("Tudo certo :)", "A configuração de normalização foi atualizada!", "success");
          setIsSavingNormalization(false);
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }else{
        try {
          const response = await api.post('normalization-rules', data);
          toggleModalNormalization();
          swal("Feito!", "A configuração de normalização foi incluída! :)", "success");
          setIsSavingNormalization(false);
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

        }catch(err: any) {
          setIsSavingNormalization(false);
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }

      setIsSavingAvailableAnswer(false);
      getAvailableAnswers(researchQuestionId!);
      getNormalizationRules(researchQuestionId!);
      getActiveResearch(researchId!);
      
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
      setIsSavingAvailableAnswer(false);
    }


  }

  const handleSubmitResearchZone = async() => {

    setIsSavingResearchZone(true);

    const data = {
      zone_name: inputZoneNameRef.current!.value,
      zone_type: dropdownZoneTypeValue,
      research: researchId
    }

    try {

      const schema = Yup.object().shape({
        zone_name:
          Yup.string()
          .required('O título da questão (pergunta) precisa ser específicado.')
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdateResearchZone) {

        try {
          const response = await api.put(`research-zones/${researchZoneId}`, data);
          toggleModalResearchZone();
          swal("Tudo certo :)", "A região / zona foi atualizada!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }

      }else{
        try {
          const response = await api.post('research-zones', data);
          toggleModalResearchZone();
          swal("Feito!", "A região / zona foi incluída! :)", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }

      setIsSavingResearchZone(false);
      getActiveResearch(researchId!);

      
    }catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
      setIsSavingResearchZone(false);
    }

  }

  const handleSubmitResearchQuestion = async() => {
    
    setIsSavingResearchQuestion(true);

    const data = {
      question: inputResearchQuestionRef.current!.value,
      question_order: inputResearchQuestionOrderRef.current!.value,
      is_multiple: inputQuestionIsMultipleRef.current!.checked,
      allow_spontaneous_response: InputAllowSpontaneousResponseRef.current!.checked,
      research: researchId
    }

    try {

      const schema = Yup.object().shape({
        question:
          Yup.string()
          .required('O título da questão (pergunta) precisa ser específicado.'),
        question_order:
          Yup.number()
          .required('Um número para definir a ordem da pergunta no formulário precisa ser informada.').min(1, 'A ordem não pode ser menor que 1.')
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdateResearchQuestion) {
        try {
          const response = await api.put(`research-questions/${researchQuestionId}`, data);
          toggleModalResearchQuestion();
          swal("Tudo certo :)", "A questão foi atualizada!", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }else{
        try {
          const response = await api.post('research-questions', data);
          //toggleModal();
          swal("Feito!", "A questão foi incluída! Você já pode configurar as possíveis respostas para ela :)", "success");
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

          const idx = response.data.id;
          handleResearchQuestionsRowClicked(idx, false);
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      }
      
      setIsSavingResearchQuestion(false);
  
      getActiveResearch(researchId!);

    }catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
      setIsSavingResearchQuestion(false);
    }

  }

  const handleSubmitSimulation = async() => {
    try {
      radioItems.map((question) => {
        if (activeResearch?.zones.length === 0) {
          throw new Error("Não existe nenhuma região ou zona configurada para esta pesquisa.");
        }

        if (question.avaliableAnswers.some(answer => answer.selected === true) === false) {
          throw new Error("algo deixou de ser preenchido ou não está configurado corretamente. Se você excluiu algum campo, clique em Limpar e recomece o teste.");
        }
      })
      swal("Tudo certo :)", "A pesquisa foi testada sem nenhum erro aparente.", "success");
    } catch (error) {
      swal("Oppss!", `Erro: ${error}`, "error");
    }
    
  }

  const handleSubmitResearch = async () => {

    try {

      setIsSaving(true);

      let agents: [{ id?: string }] = [{}];
      selectedAgentsInResearch?.map((agent) => {
        agents.push({ id: agent.value });
      });

      if (!agents) {
        await swal("Oppss!", 'Uma pesquisa não pode ficar sem pelo menos um agente responsável por ela.', "error");
        setIsSaving(false);
        return;
      }

      const data = {
        title: inputResearchTitleRef.current!.value,
        agents,
        is_active: false,
        is_completed: false,
        uf: selectedUf?.value,
        city: selectedCity?.value,
        reference_year: selectedReferenceYear.value,
        reliability: selectedReliability.value,
        margin_of_error: marginOfErrorValue
      }

      const schema = Yup.object().shape({
        title:
          Yup.string()
          .required('Um título (uma identificação) precisa ser dada a esta pesquisa'),
        uf:
          Yup.string()
          .required('Um estado precisa ser definido'),
        city:
          Yup.string()
          .required('Uma cidade precisa ser selecionada'),
        reference_year:
          Yup.string()
          .required('Um ano de referência para a amostra precisa ser definido'),
        reliability:
          Yup.string()
          .required('A confiabilidade da pesquisa precisa ser configurada'),
          margin_of_error:
          Yup.string()
          .required('A margem de erro precisa ser configurada'),
      });

      await schema.validate(data, {abortEarly: false} );

      if (isUpdate) {

        data.is_active = inputIsActiveRef.current!.checked;
        
        if (activeResearch?.is_active) {
          data.is_completed = inputIsCompletedRef.current!.checked;
        }

        const store = async () => {
          const response = await api.put(`researches/${researchId}`, data);
          setIsSaving(false);
          toggleModal();
          swal("Tudo certo :)", "A pesquisa foi atualizada!", "success");
          getResearches();
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }

        if (activeResearch?.is_active === false && data.is_active) {
          swal({
            title: "Gostaria realmente de publicar a pesquisa?",
            text: "Ao publicar a pesquisa, os agentes inseridos nela poderão inserir entrevistas. Além disso, não mais será possível editar esta pesquisa, exceto remover ou adicionar agentes ou encerrá-la. Essa ação é irreversível. Tem certeza?",
            icon: "info",
            buttons: ['Desistir', 'Continuar'],
            dangerMode: false,
          })
          .then(async (accept) => {
            if (accept) {
              await store();
            }
            setIsSaving(false);
          });
          return;
        }
        
        if (activeResearch?.is_completed === false && data.is_completed) {
          swal({
            title: "Gostaria realmente de encerrar a pesquisa?",
            text: "Ao encerrar a pesquisa, novas entrevistas não serão mais recebidas. Essa ação é irreversível. Tem certeza?",
            icon: "info",
            buttons: ['Desistir', 'Continuar'],
            dangerMode: false,
          })
          .then(async (accept) => {
            if (accept) {
              await store();
              setIsSaving(false);
            }
          });
          return;
        }

        await store();

      }else{

        const response = await api.post('researches', data);
        //toggleModal();
        swal("Feito!", "A pesquisa foi incluída! Você já pode configura-la :)", "success");

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

        const idx = response.data.id;
        handleResearchesRowClicked(idx, false);
      
        setIsSaving(false);
    
        getResearches();
      }
      
    }catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      await swal("Oppss!", `Erro: ${err}`, "error");
      setIsSaving(false);
    }

  }

  const handleClickResearchDelete = () => {
    swal({
      title: "Deseja realmente excluir esta pesquisa?",
      text: "Ao excluir a pesquisa, estará também excluindo todas as suas configurações e respostas coletadas. Tem certeza?",
      icon: "info",
      buttons: ['Desistir', 'Continuar'],
      dangerMode: true,
    })
    .then(async (accept) => {
      if (accept) {

        try {

          const response = await api.delete(`researches/${researchId}`);
          toggleModal();
          setActiveResearch(null);

          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
          
          getResearches();

          await swal("A pesquisa foi removida.", {
            icon: "success",
          });
          
        }catch(err: any){
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      } else {
        swal("Ok, a pesquisa foi mantida.");
      }
    });
  }

  const handleClickResearchQuestionDelete = () => {
    swal({
      title: "Deseja realmente excluir esta pergunta?",
      text: "Ao excluir a pergunta, estará também excluindo suas possíveis repostas, bem como as respostas coletadas para ela. Tem certeza?",
      icon: "info",
      buttons: ['Desistir', 'Continuar'],
      dangerMode: true,
    })
    .then(async (accept) => {
      if (accept) {

        try {

          const response = await api.delete(`research-questions/${researchQuestionId}`);
          toggleModalResearchQuestion();
          getActiveResearch(researchId!);

          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

          await swal("Os dados foram removidos.", {
            icon: "success",
          });
          
        }catch(err: any){
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      } else {
        swal("Ok, os dados foram mantidos.");
      }
    });
  }

  const handleClickAvailableAnswerDelete = () => {
    swal({
      title: "Deseja realmente excluir esta possível resposta?",
      text: "Ao excluir a possível resposta, está excluindo também as possíveis respostas coletadas para ela. Tem certeza?",
      icon: "info",
      buttons: ['Desistir', 'Continuar'],
      dangerMode: true,
    })
    .then(async (accept) => {
      if (accept) {

        try {

          const response = await api.delete(`available-answers/${availableAnswerId}`);
          toggleModalAvailableAnswer();
          getAvailableAnswers(researchQuestionId!);

          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

          await swal("Os dados foram removidos.", {
            icon: "success",
          });
          
        }catch(err: any){
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      } else {
        swal("Ok, os dados foram mantidos.");
      }
    });
  }

  const handleClickNormalizationDelete = () => {
    swal({
      title: "Deseja realmente excluir esta configuração de normalização?",
      text: "Ao excluir esta configuração, novas análises, resultados e relatórios serão afetados. Tem certeza?",
      icon: "info",
      buttons: ['Desistir', 'Continuar'],
      dangerMode: true,
    })
    .then(async (accept) => {
      if (accept) {

        try {

          const response = await api.delete(`normalization-rules/${normalizationId}`);
          toggleModalNormalization();
          getNormalizationRules(researchQuestionId!);

          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

          await swal("Os dados foram removidos.", {
            icon: "success",
          });
          
        }catch(err: any){
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      } else {
        swal("Ok, os dados foram mantidos.");
      }
    });
  }

  const handleClickResearchZoneDelete = () => {
    swal({
      title: "Deseja realmente excluir esta zona de pesquisa?",
      text: "Ao excluir a região ou zona, estará excluindo também as possíveis respostas coletadas para ela. Tem certeza?",
      icon: "info",
      buttons: ['Desistir', 'Continuar'],
      dangerMode: true,
    })
    .then(async (accept) => {
      if (accept) {

        try {

          const response = await api.delete(`research-zones/${researchZoneId}`);
          toggleModalResearchZone();
          getActiveResearch(researchId!);

          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }

          await swal("Os dados foram removidos.", {
            icon: "success",
          });
          
        }catch(err: any){
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro: ${err}`, "error");
        }
      } else {
        swal("Ok, os dados foram mantidos.");
      }
    });
  }

  const getNormalizationRules = async(id: string) => {
    try{
      const response = await api.get(`normalization-rules/list-by-question/${id}`);

      setNormalizationRules(response.data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }
  }

  const getAvailableAnswers = async(id: string) => {
    try{
      const response = await api.get(`available-answers/list-by-question/${id}`);
      
      const data: AvailableAnswer[] = response.data;
      data.filter(item => item.is_custom === false);

      setAvailableAnswers(data);

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }
  }

  const getAgents = async () => {

    try{

      const response = await api.get('agents');

      setResearchAgents(() => {
        
        const data: ResearchAgent[] = response.data;
        const dataFormated: SelectDataFormat[] = [];

        data.map(agent => {
          dataFormated.push( {value: agent.id, label: `@${agent.username}`} );
        });

        return dataFormated;
      });
      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }

  }

  const getActiveResearch = async (id: string) => {

    try{

      const response = await api.get(`researches/${id}`);

      if (response.data) {
        setActiveResearch(response.data);
        setResearchZones(response.data.zones);
        setResearchQuestions(response.data.questions);

        setRadioItems(response.data.questions);

      }

      const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }

    }catch(err: any){
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    }

  }

  const handleChangeRadioItem = (questionId: string, answerId: string, isChecked: boolean) => {
    if (radioItems.length) {
      setRadioItems(current => {
        const questionIndex = current.findIndex((item => item.id === questionId));
        if (current[questionIndex].is_multiple) {
          current[questionIndex].avaliableAnswers.map((answer) => {
            if (answer.id === answerId) {
              answer.selected = isChecked;
            }
          });
          return current;
        }

        current[questionIndex].avaliableAnswers.map((answer) => {
          if (answer.selected) {
            answer.selected = false;
          }
          if (answer.id === answerId) {
            answer.selected = isChecked;
          }
        });
        return current;
      })
    }
  }

  const getUfsFromIBGE = () => {
    setCities([{}] as SelectDataFormat[]);
    axios
    .get<IBGEUFResponse[]>(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    )
    .then((response) => {
      const ufs: SelectDataFormat[] = [];
      response.data.map((uf) => {
        ufs.push({value: uf.sigla, label: uf.sigla});
      });
      setUfs(ufs);
    });
  };

  const getCitiesFromIBGE = () => {
    if (ufs) {
      axios
      .get<IBGECityResponse[]>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf?.value}/municipios`
      )
      .then((response) => {
        const cities: SelectDataFormat[] = [];
        response.data.map((city) => {
          cities.push({value: city.nome, label: city.nome});
        });
        setCities(cities);
      });
    }
  };

  const handleChangeSelectUf = (
    newValue: SingleValue<SelectDataFormat> | MultiValue<SelectDataFormat>,
    actionMeta: ActionMeta<SelectDataFormat>
  ) => {
    if (!Array.isArray(newValue)) {
      const singleValue = newValue as SingleValue<SelectDataFormat>;
      if (singleValue) {
        setSelectedUf(singleValue);
        if (singleValue !== selectedUf) {
          setSelectedCity(null);
        }
      }
    }
  };
  
  const handleChangeSelectCity = (
    newValue: SingleValue<SelectDataFormat> | MultiValue<SelectDataFormat>,
    actionMeta: ActionMeta<SelectDataFormat>
  ) => {
    if (!Array.isArray(newValue)) {
      const singleValue = newValue as SingleValue<SelectDataFormat>;
      if (singleValue) {
        setSelectedCity(singleValue);
      }
    }
  };  

  const handleChangeSelectReferenceYear = (
    newValue: SingleValue<SelectDataFormat> | MultiValue<SelectDataFormat>,
    actionMeta: ActionMeta<SelectDataFormat>
  ) => {
    if (!Array.isArray(newValue)) {
      const singleValue = newValue as SingleValue<SelectDataFormat>;
      if (singleValue) {
        setSelectedReferenceYear(singleValue);
      }
    }
  };
  
  const handleChangeSelectReliability = (
    newValue: SingleValue<SelectDataFormat> | MultiValue<SelectDataFormat>,
    actionMeta: ActionMeta<SelectDataFormat>
  ) => {
    if (!Array.isArray(newValue)) {
      const singleValue = newValue as SingleValue<SelectDataFormat>;
      if (singleValue) {
        setSelectedReliability(singleValue);
      }
    }
  };
  
  const CustomSingleValue: React.FC<SingleValueProps<SelectDataFormat>> = (props) => {
    const { children, data, selectProps } = props;
  
    const placeholder = selectProps?.placeholder ? selectProps.placeholder : 'Selecione um valor';
  
    return (
      <components.SingleValue {...props}>
        <span style={{ position: 'absolute', left: 0, right: 0, textAlign: 'center', fontSize: '0.9em' }}>
          {children}
        </span>
        <span style={{ position: 'absolute', top: 0, left: 0, right: 0, textAlign: 'center', color: '#999', fontSize: '0.8em' }}>
          <small>{placeholder}</small>
        </span>
      </components.SingleValue>
    );
  };

  const CustomMultiValue: React.FC<MultiValueProps<SelectDataFormat>> = (props) => {
    const { children, data, selectProps } = props;
  
    const placeholder = selectProps?.placeholder ? selectProps.placeholder : 'Selecione um valor';
  
    return (
      <components.MultiValue {...props}>
        <span style={{ fontSize: '0.8em' }}>
          {children}
        </span>
        <span style={{ position: 'absolute', top: 0, left: 0, right: 0, textAlign: 'center', color: '#999', fontSize: '0.8em' }}>
          <small>{placeholder}</small>
        </span>
      </components.MultiValue>
    );
  };  

  const handleChangeMarginOfError = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
  
    // Substitui ponto por vírgula e remove caracteres não numéricos exceto a vírgula
    val = val.replace(/\./g, ',').replace(/[^0-9,]/g, '');
  
    // Limita o input a até 3 dígitos antes da vírgula e 2 dígitos após
    const parts = val.split(',');
    if (parts.length > 1) {
      val = parts[0].slice(0, 3) + ',' + parts[1].slice(0, 2);
    } else {
      val = parts[0].slice(0, 3);
    }
  
    setMarginOfErrorValue(val);
  };
  
  const handleBlurMarginOfError = () => {
    // Remove a vírgula no final, se houver, e converte de volta para formato de ponto para comparação numérica
    let val = marginOfErrorValue.replace(/,$/, '').replace(',', '.');
  
    // Assegura que o valor numérico seja no máximo 100 e trata valores vazios ou apenas com vírgula
    if (val === '' || val === '.') {
      setMarginOfErrorValue('');
    } else {
      const numericValue = parseFloat(val);
      if (numericValue > 100) {
        val = '100';
      } else if (numericValue < 0) {
        val = '0';
      }
      // Converte novamente para o formato com vírgula antes de atualizar o estado
      setMarginOfErrorValue(val.replace('.', ','));
    }
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      {loading ? <AnimationContainer animationType='loadingDots' visible={true}/> : null} 
      <DefaultNavbar />
      <div className="d-flex flex-column eader pb-8 pt-5 pt-md-4" style={{ minHeight: '80vh' }}>
        <Container fluid>

          <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
            <ModalHeader className="bg-secondary" toggle={toggleModal}>
              { isUpdate ? 'Editar Pesquisa' : 'Adicionar Pesquisa' }
            </ModalHeader>
            <ModalBody className="bg-secondary">

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggleTabResearch('1'); }}
                  >
                    Cadastro
                  </NavLink>
                </NavItem>
                {
                  isUpdate
                  ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggleTabResearch('2'); }}
                    >
                      Regiões e Zonas
                    </NavLink>
                  </NavItem>
                  : null
                }
                {
                  isUpdate
                  ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { toggleTabResearch('3'); }}
                    >
                      Questionário
                    </NavLink>
                  </NavItem>
                  : null
                }
                {
                  isUpdate
                  ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => { toggleTabResearch('4'); }}
                    >
                      Simulação
                    </NavLink>
                  </NavItem>
                  : null
                }
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12"md="12">
                      <Form role="form"> 
                        <br/>

                        <Row>
                          <Col sm="12"md="12">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <MdTitle/>
                                </InputGroupText>
                                <Input 
                                  id="inputResearchTitle"
                                  disabled={activeResearch?.is_active}
                                  innerRef={inputResearchTitleRef} 
                                  name="title" 
                                  placeholder="Dê um nome a esta pesquisa" 
                                  type="text"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="12"md="12">
                            {
                              activeResearch?.is_completed
                              ?
                              <Alert className="alert-default">
                                A pesquisa foi executada por
                                {
                                  activeResearch?.agents?.map((agent, i, { length } ) => (
                                    <CustomLabel key={agent.id}>
                                      {
                                        length === 1
                                        ? `\xa0apenas por @${agent.username}.` 
                                        : i + 1 === length
                                          ? `\xa0e @${agent.username}.` 
                                          : `\xa0@${agent.username},`
                                      }
                                    </CustomLabel>
                                  ))
                                }
                              </Alert>
                              :
                              <FormGroup className="mb-3">
                                <Select
                                  options={researchAgents}
                                  placeholder="Quem faz parte dessa pesquisa?" 
                                  components={{ MultiValue: CustomMultiValue }}
                                  noOptionsMessage={ () => "Ninguém foi encontrado :(" }
                                  isMulti
                                  value={selectedAgentsInResearch}
                                  onChange={ handleChangeSelectedAgentsInResearch }
                                />  
                              </FormGroup>
                            }
                            {
                              activeResearch?.is_active && activeResearch.is_completed === false
                              ?
                              <Alert className="alert-default">
                                As configurações de agentes adicionados a esta pesquisa estão disponíveis pois apesar de publicada, a pesquisa não está encerrada. Você pode desligar agentes da pesquisa ou adicionar novos agentes, se for necessário.
                              </Alert>
                              : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="4" md="4">
                            <FormGroup className="mb-3">
                              <Select 
                                placeholder = "Selecione o estado"
                                noOptionsMessage={() => "Sem mais opções"}
                                onFocus={getUfsFromIBGE}
                                options={ufs} 
                                onChange={handleChangeSelectUf}
                                components={{ SingleValue: CustomSingleValue }}
                                value={selectedUf}
                                isDisabled={activeResearch?.is_active}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="8" md="8">
                            <FormGroup className="mb-3">
                              <Select 
                                placeholder = "Selecione a cidade"
                                noOptionsMessage={() => "UF não selecionada ou cidade não encontrada."}
                                onFocus={getCitiesFromIBGE}
                                options={cities} 
                                onChange={handleChangeSelectCity}
                                components={{ SingleValue: CustomSingleValue }}
                                value={selectedCity}
                                isDisabled={activeResearch?.is_active}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="4" md="4">
                            <FormGroup className="mb-3">
                              <Select 
                                placeholder="Selecione o ano de referência"
                                noOptionsMessage={() => "Sem mais opções"}
                                options={referenceYears} 
                                onChange={handleChangeSelectReferenceYear}
                                components={{ SingleValue: CustomSingleValue }}
                                isDisabled={activeResearch?.is_active}
                                isMulti={false}
                                isClearable={false}
                                value={selectedReferenceYear}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="4" md="4">
                            <FormGroup className="mb-3">
                              <Select 
                                placeholder="Selecione a confiabilidade"
                                noOptionsMessage={() => "Sem mais opções"}
                                options={reliabilities} 
                                onChange={handleChangeSelectReliability}
                                components={{ SingleValue: CustomSingleValue }}
                                isDisabled={activeResearch?.is_active}
                                isMulti={false}
                                isClearable={false}
                                value={selectedReliability}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="4" md="4">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <FaPercentage />
                                </InputGroupText>
                                <Input 
                                  id="inputMarginOfError"
                                  disabled={activeResearch?.is_active || false}
                                  name="margin_of_error"
                                  placeholder="Margem de erro alvo"
                                  type="text"
                                  value={marginOfErrorValue}
                                  onChange={handleChangeMarginOfError}
                                  onBlur={handleBlurMarginOfError}
                                />
                                <InputGroupText>%</InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        {
                          voters ? (
                            <Row>
                              <Col sm="12" md="12">
                                <Alert className="alert-default">
                                  {recommendedSample ? 
                                    `Esta cidade tem um eleitorado total de ${voters.total_electorate} pessoas aptas a votar. Com base na margem de erro e no nível de confiança selecionados, a amostra recomendada é de ${recommendedSample} pessoas.` :
                                    `Esta cidade tem um eleitorado total de ${voters.total_electorate} pessoas aptas a votar. Informe a margem de erro alvo e selecione o nível de confiança para visualizar a amostra necessária.`
                                  }
                                </Alert>
                              </Col>
                            </Row>
                          ) : null
                        }

                        {
                          isUpdate
                          ?
                          <Row>
                            <Col sm="12"md="12">
                              <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      disabled={activeResearch?.is_active}
                                      innerRef={inputIsActiveRef}
                                    />{' '}
                                    <strong>Pesquisa publicada?</strong> (não será possível editar após publicá-la)
                                  </Label>
                                </InputGroup>
                              </FormGroup>
                            </Col>

                            {
                              <Col sm="12"md="12">
                                <FormGroup className="mb-3">
                                  <InputGroup className="input-group-alternative">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        disabled={activeResearch?.is_completed}
                                        innerRef={inputIsCompletedRef}
                                      />{' '}
                                      <strong>Pesquisa encerrada?</strong> (não será possível receber novas respostas após encerra-la)
                                    </Label>
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            }
                          </Row>
                          : null
                        }

                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <br/>
                      <Button size="sm" disabled={activeResearch?.is_active} onClick={handleNewResearchZone} color="default" type="button">
                        Incluir região / zona
                      </Button>
                      <br/><br/>
                      <MUIDataTable
                        title={"Regiões onde será aplicada"}
                        data={researchZones}
                        columns={[
                          {
                            name: "id",
                            label: "id",
                            options: {
                              filter: false,
                              display: "excluded"
                            }
                          },
                          {
                            name: "zone_name",
                            label: "Nome da região",
                            options: {
                              filter: true,
                              sort: true
                            }
                          },
                          {
                            name: "zone_type",
                            label: "Tipo de zona",
                            options: {
                              filter: true,
                              sort: true
                            }
                          },
                        ]}
                        options={{
                          selectableRows: 'none',
                          onRowClick: (rowData) => handleResearchZonesRowClicked(rowData[0]),
                          textLabels: {
                            body: {
                              noMatch: "Desculpe, nenhum registro encontrado",
                              toolTip: "Ordenar",
                              columnHeaderTooltip: (column) => `Ordenar por ${column.label}`
                            },
                            pagination: {
                              next: "Próxima Página",
                              previous: "Página Anterior",
                              rowsPerPage: "Registros por página:",
                              displayRows: "de",
                            },
                            toolbar: {
                              search: "Buscar",
                              downloadCsv: "Baixar CSV",
                              print: "Imprimir",
                              viewColumns: "Visualizar Colunas",
                              filterTable: "Filtrar Tabela",
                            },
                            filter: {
                              all: "Tudo",
                              title: "FILTROS",
                              reset: "LIMPAR",
                            },
                            viewColumns: {
                              title: "Exibir Colunas",
                              titleAria: "Exibir/Ocultar Colunas da Tabela",
                            },
                            selectedRows: {
                              text: "registro(s) selecionados",
                              delete: "Apagar",
                              deleteAria: "Apagar registros selecionados",
                            },
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <br/>
                      <Button size="sm" disabled={activeResearch?.is_active} onClick={handleNewResearchQuestion} color="default" type="button">
                        Incluir questão
                      </Button>
                      <br/><br/>
                      <MUIDataTable
                        title={"Questões da pesquisa"}
                        data={researchQuestions}
                        columns={
                          [
                            {
                              name: "id",
                              label: "id",
                              options: {
                                filter: false,
                                display: "excluded"
                              }
                            },
                            {
                              name: "question",
                              label: "Questão",
                              options: {
                                filter: true,
                                sort: true
                              }
                            },
                          ]
                        }
                        options={
                          {
                            selectableRows: 'none',
                            onRowClick: (rowData: string[]) => {handleResearchQuestionsRowClicked(rowData[0], true)},
                            textLabels: {
                              body: {
                                noMatch: "Desculpe, nenhum registro encontrado",
                                toolTip: "Ordernar",
                                columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                              },
                              pagination: {
                                next: "Próxima Página",
                                previous: "Página Anterior",
                                rowsPerPage: "Registros por página:",
                                displayRows: "de",
                              },
                              toolbar: {
                                search: "Buscar",
                                downloadCsv: "Baixar CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar Colunas",
                                filterTable: "Filtrar Tabela",
                              },
                              filter: {
                                all: "Tudo",
                                title: "FILTROS",
                                reset: "LIMPAR",
                              },
                              viewColumns: {
                                title: "Exibir Colunas",
                                titleAria: "Exibir/Ocultar Colunas da Tabela",
                              },
                              selectedRows: {
                                text: "registro(s) selecionados",
                                delete: "Apagar",
                                deleteAria: "Apagar registros selecionados",
                              },
                            }
                          }
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col md="12">
                      <br/>
                      <Alert className="alert-warning">
                        <strong>Atenção: isto é uma simulação e os dados não serão salvos.</strong> Use para simular a coleta dos dados e verificar como sua pesquisa está ficando.
                      </Alert>
                      
                        <strong>{ activeResearch?.title }</strong> <br/>
                        <ZoneContainer>
                          <CustomLabel>Regiões onde será aplicada: &nbsp;</CustomLabel>
                          {
                            researchZones.length
                            ? researchZones.map((zone, i, { length }) => (
                              <CustomLabel key={zone.id}>
                                { 
                                  length === 1
                                  ? `apenas em ${zone.zone_name} - zona ${zone.zone_type}.` 
                                  : i + 1 === length
                                    ? `e ${zone.zone_name} - zona ${zone.zone_type}.` 
                                    : `${zone.zone_name} - zona ${zone.zone_type},\xa0`
                                }
                              </CustomLabel>
                            ))
                            : <CustomLabel>não há regiões ou zonas configuradas</CustomLabel>
                          }
                        </ZoneContainer>
                      
                      {
                        radioItems?.map((question) => (
                          <div key={question.id} >
                            <br/>
                            {
                              question.is_multiple
                              ?
                              <>
                                <QuestionTitleBold>{ question.question } </QuestionTitleBold> <QuestionTitle>(múltiplas opções) { question.allow_spontaneous_response ? '| (permite respostas espontâneas)' : null } </QuestionTitle><br/>
                                {
                                  question.avaliableAnswers?.filter(item => item.is_custom === false).map((answer) => (
                                    <FormGroup key={answer.id} check>
                                      <Label check>
                                        <Input type="checkbox" onChange={(e) => handleChangeRadioItem(question.id, answer.id, e.target.checked)} />{' '}
                                        { answer.answer }
                                      </Label>
                                    </FormGroup>
                                  ))
                                }
                                {
                                  question.avaliableAnswers?.some(answer => answer.is_custom === true)
                                  ?
                                    <>
                                      <CustomLabel>Respostas incluídas de forma espontânea:</CustomLabel>
                                      {
                                        question.avaliableAnswers?.filter(item => item.is_custom === true).map((answer) => (
                                          <FormGroup key={answer.id} check>
                                            <Label check>
                                              <Input type="checkbox" onChange={(e) => handleChangeRadioItem(question.id, answer.id, e.target.checked)} />{' '}
                                              { answer.answer }
                                            </Label>
                                          </FormGroup>
                                        ))
                                      }
                                    </>
                                  : null
                                }
                              </>
                              : 
                              <>
                              <QuestionTitleBold>{ question.question }</QuestionTitleBold> <QuestionTitle> { question.allow_spontaneous_response ? '| (permite respostas espontâneas)' : null } </QuestionTitle> <br/>
                                {
                                  question.avaliableAnswers?.filter(item => item.is_custom === false).map((answer) => (
                                    <div onChange={() => handleChangeRadioItem(question.id, answer.id, true)} key={answer.id} >
                                      <input type="radio" id={answer.id} value={answer.id} name={question.id} /> <CustomLabel htmlFor={answer.id} >{ answer.answer }</CustomLabel>
                                    </div>
                                  ))
                                }
                                {
                                  question.avaliableAnswers?.some(answer => answer.is_custom === true) 
                                  ?
                                    <>
                                      <CustomLabel>Respostas incluídas de forma espontânea:</CustomLabel>
                                      {
                                        question.avaliableAnswers?.filter(item => item.is_custom === true).map((answer) => (
                                          <div onChange={() => handleChangeRadioItem(question.id, answer.id, true)} key={answer.id} >
                                            <input type="radio" id={answer.id} value={answer.id} name={question.id} /> <CustomLabel htmlFor={answer.id} >{ answer.answer }</CustomLabel>
                                          </div>
                                        ))
                                      }
                                    </>
                                  : null
                                }
                              </>
                            }
                            {
                              question.allow_spontaneous_response
                              ? 
                              <>
                                <Button 
                                  color="link" 
                                  className="mr-auto" 
                                  type="button"
                                  onClick={() => handleNewCustomAnswerOption(question.id)}
                                >
                                  <small><strong>outra opção?</strong></small>
                                </Button>
                              </>
                              : null
                            }
                          </div>
                        ))
                      }
                      {
                        radioItems.length
                        ? 
                        <>
                          <br/>
                          <Button color="default" onClick={handleSubmitSimulation}> Simular </Button>
                          <Button color="link" onClick={clearSimulation}> Limpar e Recomeçar </Button>
                        </>
                        : null
                      }
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              {
                isUpdate && activeTab === '1'
                ? user.is_admin ? <Button color="link" className="mr-auto" onClick={handleClickResearchDelete}>excluir?</Button> : null
                : null
              }
              <Button color="default" disabled={isSaving || activeResearch?.is_completed} onClick={handleSubmitResearch}>
                { isSaving
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : isUpdate
                    ? 'Salvar'
                    : 'Salvar e configurar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <Modal className="modal-lg" isOpen={modalResearchQuestion} toggle={toggleModalResearchQuestion}>
            <ModalHeader className="bg-secondary" toggle={toggleModalResearchQuestion}>
              { isUpdateResearchQuestion ? 'Editar Questão' : 'Adicionar Questão' }
            </ModalHeader>
            <ModalBody className="bg-secondary">

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTabResearchQuestion === '1' })}
                    onClick={() => { toggleTabResearchQuestion('1'); }}
                  >
                    Questão (Pergunta)
                  </NavLink>
                </NavItem>
                {
                  isUpdateResearchQuestion
                  ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabResearchQuestion === '2' })}
                      onClick={() => { toggleTabResearchQuestion('2'); }}
                    >
                      Possíveis respostas
                    </NavLink>
                  </NavItem>
                  : null
                }
                {
                  isUpdateResearchQuestion
                  ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabResearchQuestion === '3' })}
                      onClick={() => { toggleTabResearchQuestion('3'); }}
                    >
                      Normalização
                    </NavLink>
                  </NavItem>
                  : null
                }
              </Nav>

              <TabContent activeTab={activeTabResearchQuestion}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Form role="form"> 
                        <br/>

                        <Row>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <MdTitle/>
                                </InputGroupText>
                                <Input 
                                  id="inputResearchQuestion"
                                  disabled={activeResearch?.is_active}
                                  innerRef={inputResearchQuestionRef} 
                                  name="question" 
                                  placeholder="Dê um título para esta pergunta. Exemplo: em quem você irá votar para (...)" 
                                  type="text"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                <InputGroupText addonType="prepend">
                                  <GoListOrdered/>
                                </InputGroupText>
                                <Input 
                                  id="inputResearchQuestionOrder"
                                  disabled={activeResearch?.is_active}
                                  innerRef={inputResearchQuestionOrderRef} 
                                  name="orderNumber" 
                                  placeholder="Ordem no questionário (somente números)." 
                                  type="number"
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    disabled={activeResearch?.is_active}
                                    innerRef={inputQuestionIsMultipleRef}
                                  />{' '}
                                  <strong>Multipla resposta?</strong> (esta pergunta permite mais de uma resposta por entrevistado)
                                </Label>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <InputGroup className="input-group-alternative">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    disabled={activeResearch?.is_active}
                                    innerRef={InputAllowSpontaneousResponseRef}
                                  />{' '}
                                  <strong>Permite resposta espontânea?</strong> (esta pergunta permite a inclusão de respostas espontâneas do entrevistado)
                                </Label>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>

                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <br/>
                      <Button size="sm" disabled={activeResearch?.is_active} onClick={handleNewAvailableAnswer} color="default" type="button">
                        Incluir possível resposta
                      </Button>
                      <br/><br/>
                      <MUIDataTable
                        title={"Possíveis respostas para a pergunta"}
                        data={availableAnswers}
                        columns={
                          [
                            {
                              name: "id",
                              label: "id",
                              options: {
                                filter: false,
                                display: "excluded"
                              }
                            },
                            {
                              name: "answer",
                              label: "Possível resposta",
                              options: {
                                filter: true,
                                sort: true
                              }
                            },
                          ]
                        }
                        options={
                          {
                            selectableRows: 'none',
                            onRowClick: (rowData: string[]) => {handleAvaliableAnswerRowClicked(rowData[0])},
                            textLabels: {
                              body: {
                                noMatch: "Desculpe, nenhum registro encontrado",
                                toolTip: "Ordernar",
                                columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                              },
                              pagination: {
                                next: "Próxima Página",
                                previous: "Página Anterior",
                                rowsPerPage: "Registros por página:",
                                displayRows: "de",
                              },
                              toolbar: {
                                search: "Buscar",
                                downloadCsv: "Baixar CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar Colunas",
                                filterTable: "Filtrar Tabela",
                              },
                              filter: {
                                all: "Tudo",
                                title: "FILTROS",
                                reset: "LIMPAR",
                              },
                              viewColumns: {
                                title: "Exibir Colunas",
                                titleAria: "Exibir/Ocultar Colunas da Tabela",
                              },
                              selectedRows: {
                                text: "registro(s) selecionados",
                                delete: "Apagar",
                                deleteAria: "Apagar registros selecionados",
                              },
                            }
                          }
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <br/>
                      <Button size="sm" onClick={handleNewNormalization} color="default" type="button">
                        Incluir normalização
                      </Button>
                      <br/>
                      <small id="TooltipWhatIsNormalization">O que é uma configuração de normalização?</small>
                      <br/><br/>
                      <Tooltip placement="right" isOpen={tooltipWhatIsNormalizationOpen} target="TooltipWhatIsNormalization" toggle={() => setTooltipWhatIsNormalizationOpen(!tooltipWhatIsNormalizationOpen)}>
                        Ajude a IA a agrupar corretamente as respostas. Uma configuração de normalização pode ajudar a atribuir apelidos e formas alternativas de escrever um nome para um nome específico. Isso facilita e eleva a qualidade de trabalho do modelo de agrupamento de respostas espontâneas.
                        <br/><br/>
                        Você pode configurar "Chiquinho" e/ou "Chiquinho da Van" para ser interpretado como "Francisco Souza", por exemplo.
                        <br/><br/>
                        Essa configuração não é necessária se você não estiver usando coleta automatizada @system.web e se a pergunta não permitir respostas espontâneas.
                      </Tooltip>
                      <MUIDataTable
                        title={"Normalizações configuradas"}
                        data={normalizationRules}
                        columns={
                          [
                            {
                              name: "id",
                              label: "id",
                              options: {
                                filter: false,
                                display: "excluded"
                              }
                            },
                            {
                              name: "original_text",
                              label: "Texto Original",
                              options: {
                                filter: true,
                                sort: true
                              }
                            },
                            {
                              name: "normalized_text",
                              label: "Texto Normalizado",
                              options: {
                                filter: true,
                                sort: true
                              }
                            },
                          ]
                        }
                        options={
                          {
                            selectableRows: 'none',
                            onRowClick: (rowData: string[]) => {handleNormalizationRulesRowClicked(rowData[0])},
                            textLabels: {
                              body: {
                                noMatch: "Desculpe, nenhum registro encontrado",
                                toolTip: "Ordernar",
                                columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                              },
                              pagination: {
                                next: "Próxima Página",
                                previous: "Página Anterior",
                                rowsPerPage: "Registros por página:",
                                displayRows: "de",
                              },
                              toolbar: {
                                search: "Buscar",
                                downloadCsv: "Baixar CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar Colunas",
                                filterTable: "Filtrar Tabela",
                              },
                              filter: {
                                all: "Tudo",
                                title: "FILTROS",
                                reset: "LIMPAR",
                              },
                              viewColumns: {
                                title: "Exibir Colunas",
                                titleAria: "Exibir/Ocultar Colunas da Tabela",
                              },
                              selectedRows: {
                                text: "registro(s) selecionados",
                                delete: "Apagar",
                                deleteAria: "Apagar registros selecionados",
                              },
                            }
                          }
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
            <ModalFooter className="bg-secondary">
              {
                isUpdateResearchQuestion && activeTabResearchQuestion === '1' && activeResearch?.is_active === false
                ? user.is_admin ? <Button color="link" className="mr-auto" onClick={handleClickResearchQuestionDelete}>excluir?</Button> : null
                : null
              }
              <Button color="default" disabled={isSavingResearchQuestion || activeResearch?.is_active} onClick={handleSubmitResearchQuestion}>
                { isSavingResearchQuestion
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : isUpdateResearchQuestion
                    ? 'Salvar'
                    : 'Salvar e configurar possíveis respostas'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalResearchQuestion}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <Modal className="modal-lg" isOpen={modalAvailableAnswer} toggle={toggleModalAvailableAnswer}>
            <ModalHeader className="bg-secondary" toggle={toggleModalAvailableAnswer}>
              { isUpdateAvailableAnswer ? 'Editar possível resposta' : 'Adicionar possível resposta' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col sm="12">
                  <Form role="form"> 
                    <br/>

                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputAnswer"
                              disabled={activeResearch?.is_active}
                              innerRef={inputAnswerRef} 
                              name="answer" 
                              placeholder="Específique uma possível resposta. Exemplo: Leonel Brizola; Sim / Não e outras possíveis respostas" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <GoListOrdered/>
                            </InputGroupText>
                            <Input 
                              id="inputAnswerOrder"
                              disabled={activeResearch?.is_active}
                              innerRef={inputAnswerOrderRef} 
                              name="answerOrderNumber" 
                              placeholder="Ordem entre as possíveis respostas (somente números)." 
                              type="number"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                  </Form>
                </Col>
              </Row>                
            </ModalBody>
            <ModalFooter className="bg-secondary">
              {
                isUpdateAvailableAnswer && activeResearch?.is_active === false
                ? user.is_admin ? <Button color="link" className="mr-auto" onClick={handleClickAvailableAnswerDelete}>excluir?</Button> : null
                : null
              }
              <Button color="default" disabled={isSavingAvailableAnswer || activeResearch?.is_active} onClick={handleSubmitAvailableAnswer}>
                { isSavingAvailableAnswer
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalAvailableAnswer}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <Modal className="modal-lg" isOpen={modalNormalization} toggle={toggleModalNormalization}>
            <ModalHeader className="bg-secondary" toggle={toggleModalAvailableAnswer}>
              { isUpdateNormalization ? 'Editar normalização' : 'Adicionar normalização' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col sm="12">
                  <Form role="form"> 
                    <br/>

                    <Row>
                      <Col xxl="5" xl="5" lg="5" md="5" sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputNormalizeOriginalText"
                              innerRef={inputNormalizeOriginalTextRef} 
                              name="answer" 
                              placeholder="Texto de entrada" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xxl="2" xl="2" lg="2" md="2" sm="12">
                        <small>converter para</small> <FaArrowRight/>
                      </Col>
                      <Col xxl="5" xl="5" lg="5" md="5" sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputNormalizeNormalizedText"
                              innerRef={inputNormalizeNormalizedTextRef} 
                              name="answer" 
                              placeholder="Texto de saída" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>                
            </ModalBody>
            <ModalFooter className="bg-secondary">
              {
                isUpdateNormalization
                ? user.is_admin ? <Button color="link" className="mr-auto" onClick={handleClickNormalizationDelete}>excluir?</Button> : null
                : null
              }
              <Button color="default" disabled={isSavingNormalization} onClick={handleSubmitNormalization}>
                { isSavingNormalization
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalNormalization}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <MiniModal className="modal-lg" isOpen={modalCustomAnswerOption} toggle={toggleModalCustomAnswerOption}>
            <ModalHeader className="bg-secondary" toggle={toggleModalCustomAnswerOption}>
              Adicionar resposta espontânea
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col sm="12">
                  <Form role="form"> 
                    <br/>

                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputCustomAnswer"
                              innerRef={inputCustomAnswerRef} 
                              name="customAnswer" 
                              placeholder="Resposta informada" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>                
            </ModalBody>
            <ModalFooter className="bg-secondary">
              <Button color="default" onClick={handleSubmitCustomAnswerOption}>
                { isSavingCustomAnswerOption
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalCustomAnswerOption}>Cancelar</Button>
            </ModalFooter>
          </MiniModal>

          <Modal className="modal-lg" isOpen={modalResearchZone} toggle={toggleModalResearchZone}>
            <ModalHeader className="bg-secondary" toggle={toggleModalResearchZone}>
              { isUpdateResearchZone ? 'Editar região / zona' : 'Adicionar região / zona' }
            </ModalHeader>
            <ModalBody className="bg-secondary">
              <Row>
                <Col sm="12">
                  <Form role="form"> 
                    <br/>

                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupText addonType="prepend">
                              <MdTitle/>
                            </InputGroupText>
                            <Input 
                              id="inputZoneName"
                              disabled={activeResearch?.is_active}
                              innerRef={inputZoneNameRef} 
                              name="zoneName" 
                              placeholder="Dê um nome a esta região ou zona" 
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                          <InputGroup>
                            <Dropdown isOpen={dropdownZoneTypeOpen} disabled={activeResearch?.is_active} toggle={toggleDropdownZoneType}>
                              <DropdownToggle caret>
                                { dropdownZoneTypeValue === 'urbana' ? <FaCity className="text-info"/> : undefined }
                                { dropdownZoneTypeValue === 'rural' ? <GiFarmTractor className="text-success"/> : undefined }
                                &nbsp;{ dropdownZoneTypeValue }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  className="text-muted" 
                                  header
                                >
                                  selecione o tipo de zona
                                </DropdownItem>
                                <DropdownItem 
                                  onClick={() => { setDropdownZoneTypeValue('urbana') }}
                                >
                                  <FaCity className="text-info"/>urbana
                                </DropdownItem>
                                <DropdownItem 
                                  onClick={() => { setDropdownZoneTypeValue('rural') }}
                                >
                                  <GiFarmTractor className="text-success"/>rural
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>

                  </Form>
                </Col>
              </Row>                
            </ModalBody>
            <ModalFooter className="bg-secondary">
              {
                isUpdateResearchZone && activeResearch?.is_active === false
                ? user.is_admin ? <Button color="link" className="mr-auto" onClick={handleClickResearchZoneDelete}>excluir?</Button> : null
                : null
              }
              <Button color="default" disabled={isSavingResearchZone || activeResearch?.is_active} onClick={handleSubmitResearchZone}>
                { isSavingResearchZone
                  ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Salvando...
                    </>
                  : 'Salvar'
                }
              </Button>{' '}
              <Button color="secondary" onClick={toggleModalResearchZone}>Cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="header-body">
            <Row>
              <Col lg="9" xl="9">
              <StyledDataTable
                title={"Pesquisas"}
                data={researches}
                columns={
                  [
                    {
                      name: "id",
                      label: "id",
                      options: {
                        filter: false,
                        display: "excluded"
                      }
                    },
                    {
                      name: "title",
                      label: "Pesquisa",
                      options: {
                        filter: false,
                        sort: true
                      }
                    },
                    {
                      name: "Ações",
                      label: "Ações",
                      options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          const researchId = tableMeta.rowData[0];
                          return (
                            <Button 
                              size="sm" 
                              color="primary" 
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/resultados/${researchId}`);
                              }}
                            >
                              Visualizar Resultados
                            </Button>
                          );
                        }
                      }
                    }
                    
                  ]
                }
                options={
                  {
                    selectableRows: 'none',
                    onRowClick: (rowData: string[]) => {handleResearchesRowClicked(rowData[0], true)},
                    textLabels: {
                      body: {
                        noMatch: "Desculpe, nenhum registro encontrado",
                        toolTip: "Ordernar",
                        columnHeaderTooltip: (column: any) => `Ordernar por ${column.label}`
                      },
                      pagination: {
                        next: "Próxima Página",
                        previous: "Página Anterior",
                        rowsPerPage: "Registros por página:",
                        displayRows: "de",
                      },
                      toolbar: {
                        search: "Buscar",
                        downloadCsv: "Baixar CSV",
                        print: "Imprimir",
                        viewColumns: "Visualizar Colunas",
                        filterTable: "Filtrar Tabela",
                      },
                      filter: {
                        all: "Tudo",
                        title: "FILTROS",
                        reset: "LIMPAR",
                      },
                      viewColumns: {
                        title: "Exibir Colunas",
                        titleAria: "Exibir/Ocultar Colunas da Tabela",
                      },
                      selectedRows: {
                        text: "registro(s) selecionados",
                        delete: "Apagar",
                        deleteAria: "Apagar registros selecionados",
                      },
                    }
                  }
                }
              />
              </Col>
              <Col lg="3" xl="3">
                <Button color="default" onClick={handleNewResearch}>
                  <BsPlusCircle/> Incluir Pesquisa
                </Button>
                <br/><br/>
                <TasksNumbersCard
                  onClick={() => { navigate('/meu-perfil') }}
                  title="Pesquisas" 
                  description="Pesquisas cadastradas no sistema"
                  icon={GoTasklist} 
                  circleColor="indigo"
                  tasksQuantity={ researches.length }
                />
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      <GradientFooter/>
    </React.Fragment>
  );
}

export default Researches;