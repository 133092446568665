import React, { useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Popover,
  PopoverBody
} from "reactstrap";

import useAuth from '../../../hooks/useAuth';

import { FaRegEnvelope } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';

import { GlobalStyle, Logo } from './styles';

import SimpleFooter from '../../../components/Footers/SimpleFooter';
import { PurpleButton, DarkInput, DarkInputGroupText } from '../../../components/CustomComponents'

import logoDefault from '../../../assets/img/logo/logo-publisol-pesquisas-white.png';

const Login = () => {

  const inputEmail    = useRef<HTMLInputElement>(null);
  const inputPassword = useRef<HTMLInputElement>(null);

  const [inputEmailErrorColor, setInputEmailErrorColor] = useState('');
  const [popoverEmailOpen, setPopoverEmailOpen] = useState(false);
  const [popoverEmailMessage, setPopoverEmailMessage] = useState('');

  const [inputPasswordErrorColor, setInputPasswordErrorColor] = useState('');
  const [popoverPasswordOpen, setPopoverPasswordOpen] = useState(false);
  const [popoverPasswordMessage, setPopoverPasswordMessage] = useState('');

  const [popoverSubmitOpen, setPopoverSubmitOpen] = useState(false);
  const [popoverSubmitText, setPopoverSubmitText] = useState('');

  const { signIn } = useAuth();
  const navigate = useNavigate();

  interface SignInFormData {
    email: string;
    password: string;
  }

  async function onBlurEmail() {
    setPopoverEmailOpen(false);
  }

  async function onBlurPassword() {
    setPopoverPasswordOpen(false);
  }

  async function onChangeEmail() {

    const email = inputEmail.current?.value;

    try {
      const schema = Yup.string()
      .required('e-mail não pode ficar em branco')
      .email('parece que este não é um e-mail válido');

      await schema.validate(email);
      setInputEmailErrorColor('has-success');
      setPopoverEmailOpen(false);
      setPopoverEmailMessage('');

    } catch (err: any) {
      setInputEmailErrorColor('has-danger');
      setPopoverEmailOpen(true);
      setPopoverEmailMessage(err.message);
    }
  }

  async function onChangePassword() {

    const password     = inputPassword.current?.value;

    try {
      const schema = Yup.string()
      .min(6, 'a senha precisa ter, no mínimo, 6 caracteres');

      await schema.validate(password);
      setInputPasswordErrorColor('has-success');
      setPopoverPasswordOpen(false);
      setPopoverPasswordMessage('');

    } catch (err: any) {
      setInputPasswordErrorColor('has-danger');
      setPopoverPasswordOpen(true);
      setPopoverPasswordMessage(err.message);
    }
  }

  async function handleSubmit() {

    const email     = inputEmail.current!.value;
    const password  = inputPassword.current!.value;

    const data:SignInFormData = {
      email,
      password
    }

    try {
      const schema = Yup.object().shape({
        email: 
          Yup.string()
          .required('e-mail não pode ficar em branco')
          .email('parece que este não é um e-mail válido'),
        password: 
          Yup.string()
          .min(6, 'a senha precisa ter, no mínimo, 6 caracteres')
      });

      await schema.validate(data, {abortEarly: false} );

      setPopoverSubmitOpen(false);
      
      signIn(data, navigate);
      
    }catch (err) {
      setPopoverSubmitOpen(true);
      setPopoverSubmitText('Há campos não preenchidos corretamente!');
    }

  }

  async function handleResetPassword(e: React.FormEvent) {
    e.preventDefault();
    navigate('/resetar-senha');
  }

  async function handleRegisterUser(e: React.FormEvent) {
    e.preventDefault();
    navigate('/registrar-usuario');
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <section className="section section-shaped section-lg">
          <Container data-login-container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="mb-3" >
                  <CardHeader className="bg-gradient-indigo pb-3">
                    <div className="text-center">
                      <Logo src={logoDefault} />
                    </div>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: '#333', color: 'white' }} className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className={'mb-3 ' + inputEmailErrorColor}>
                        <InputGroup className="input-group-alternative">
                          <DarkInputGroupText addonType="prepend">
                            <FaRegEnvelope/>
                          </DarkInputGroupText>
                          <DarkInput 
                            id="inputEmail" 
                            innerRef={inputEmail} 
                            onBlur={onBlurEmail} 
                            onChange={onChangeEmail} 
                            name="email" 
                            placeholder="Email" 
                            type="email" 
                          />
                          <Popover 
                            placement="top" 
                            isOpen={popoverEmailOpen} 
                            target="inputEmail"
                          >
                            <PopoverBody>
                              {popoverEmailMessage}
                            </PopoverBody>
                          </Popover>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={'mb-3 ' + inputPasswordErrorColor}>
                        <InputGroup className="input-group-alternative">
                          <DarkInputGroupText addonType="prepend">
                            <FiLock/>
                          </DarkInputGroupText>
                          <DarkInput 
                            id="inputPassword"
                            innerRef={inputPassword} 
                            onBlur={onBlurPassword} 
                            onChange={onChangePassword} 
                            name="password" 
                            placeholder="Senha" 
                            type="password" 
                            autoComplete="off" 
                          />
                          <Popover 
                            placement="top" 
                            isOpen={popoverPasswordOpen} 
                            target="inputEmail"
                          >
                            <PopoverBody>
                              {popoverPasswordMessage}
                            </PopoverBody>
                          </Popover>
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <PurpleButton onClick={handleSubmit}
                          id="submitButton"
                          className="my-4"
                          type="button"
                        >
                          Entrar
                        </PurpleButton>
                        <Popover 
                          onClick={() => {setPopoverSubmitOpen(false)}}
                          placement="top" 
                          isOpen={popoverSubmitOpen} 
                          target="submitButton"
                        >
                          <PopoverBody>
                            { popoverSubmitText }
                          </PopoverBody>
                        </Popover>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row data-login-links className="mt-3">
                  <Col className="text-light d-none" xs="6">
                    <Link className="text-light" to="#" onClick={ handleResetPassword } >
                      <small>Perdeu sua senha?</small>
                    </Link>
                  </Col>
                  <Col className="text-right d-none" xs="6">
                    <Link className="text-light" to="#" onClick={ handleRegisterUser } >
                      <small>Criar uma nova conta</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
      </section>
      <SimpleFooter/>
    </React.Fragment>
  );
}

export default Login;