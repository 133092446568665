import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
//import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/argon-design-system-react.css';
import './App.css';

import AuthProvider from './providers/AuthProvider';

import Routes from './routes';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Router>
  );
}

export default App;