import React, { useEffect, useState, useRef, createRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, Label, Spinner, TabContent, TabPane, Alert } from 'reactstrap';
import swal from 'sweetalert';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList, PieChart, Pie } from 'recharts';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ActionMeta } from 'react-select';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { FaBrain } from "react-icons/fa";
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import ReactMarkdown from 'react-markdown';

import useAuth from '../../hooks/useAuth';
import api from '../../services/api';
import socket, { setupSocketListeners } from '../../services/socket';
import DefaultNavbar from '../../components/Navbars/DefaultNavbar';
import GradientFooter from '../../components/Footers/GradientFooter';
import AnimationContainer from '../../components/Containers/AnimationContainer';
import AnimationDelimitedArea from '../../components/Containers/AnimationDelimitedArea';
import VennDiagram from '../../components/Diagrams/VennDiagram';
import DataLegend from '../../components/Legends/DataLegend';

import {
  HeaderContainer,
  StyledButton,
  DarkSelect,
  GlobalStyle, 
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledCardText,
  DarkNav,
  DarkNavItem,
  DarkNavLink,
  StyledHeading
} from './styles';
import logoImidia2 from '../../assets/img/logo/logo-imidia-2.png';
import headerImidiaBlack from '../../assets/img/logo/imidia-header-black.png';
import BgIaWithAlpha from '../../assets/img/bg-ia-1.png';
import logoWhite from '../../assets/img/logo/logo-publisol-white.png';

type Chi2AnalysisData = {
  report: string;
  references: Chi2AnalysisResult[];
}

interface Chi2AnalysisResult {
  id: number;
  question_a: string;
  answer_a: string;
  question_b: string;
  answer_b: string;
  percentage_of_a_in_total: number;
  percentage_of_b_in_total: number;
  percentage_of_both_in_total: number;
  percentage_of_a_given_a: number;
  percentage_of_b_given_b: number;
  occurrences_of_a_in_total: number;
  occurrences_of_b_in_total: number;
  occurrences_of_both_in_total: number;
  total_interviews: number;
}

interface FetchPdfResponse {
  message: string;
  filePath: string;
  surveyDetails: SurveyDetails;
}

type SurveyDetails = {
  research: {
    id: string;
    title: string;
    uf: string;
    city: string;
    reference_year: string;
    reliability: number;
    margin_of_error: number;
    createdAt: string;
    isCompleted: boolean;
    completedAt: string | null;
  };
  aggregatedData: AggregatedData[];
  zonesData: ZoneData[];
  urbanAggregate: UrbanRuralAggregateData;
  ruralAggregate: UrbanRuralAggregateData;
  chi2AnalysisData: Chi2AnalysisData;
  interviewInfo: InterviewInfo;
};

interface SurveyDetailsAnalyzed {
  research: {
    id: string;
    title: string;
    uf: string;
    city: string;
    reference_year: string;
    reliability: number;
    margin_of_error: number;
    createdAt: string;
    isCompleted: boolean;
    completedAt: string | null;
  };
  aggregatedData: AggregatedDataProps | null;
  zonesData: ZoneData[];
  urbanAggregate: UrbanRuralAggregateData | null;
  ruralAggregate: UrbanRuralAggregateData | null;
  chi2AnalysisData: Chi2AnalysisData;
  interviewInfo: InterviewInfo;
}

interface AggregatedDataProps {
  data: AggregatedData[];
  IATitle?: string;
  IAAnalysis?: string;
}

type Answer = {
  answerId: string;
  answerText: string;
  isCustom: boolean;
  count: number;
  percentage: number;
  answer_order: number;
};

type AggregatedData = {
  questionId: string;
  questionText: string;
  answers: Answer[];
};

type ZoneData = {
  zoneId: string;
  zoneName: string;
  zoneType:string;
  IATitle?: string;
  IAAnalysis?: string;
  aggregatedData: AggregatedData[];
};

type UrbanRuralAggregateData = {
  zoneType: string;
  aggregatedData: AggregatedData[];
  IATitle?: string;
  IAAnalysis?: string;
};

type InterviewInfo = {
  firstInterviewDate: string;
  lastInterviewDate: string;
  totalInterviews: number;
  interviewsByAgent: {
    agentId: string;
    count: number;
    agentName: string;
  }[];
};

interface SelectDataFormat {
  value: string;
  label: string;
}

interface VoterData {
  state: string;
  city: string;
  gender_distribution: Record<string, number>;
  education_levels: Record<string, number>;
  age_ranges: Record<string, number>;
  total_electorate: number;
  total_with_biometry: number;
  total_with_social_name: number;
  total_with_disability: number;
  reference_year: string;
}

const options = [
  { value: 'detailed', label: 'Detalhado' },
  { value: 'presentation', label: 'Apresentação' },
  { value: 'ai-analysis', label: 'Análise de IA Generativa' }
];

const orderOption = [
  { value: 'defaultOrder', label: 'Ordem configurada' },
  { value: 'biggerToSmaller', label: 'Maiores resultados' }
];

const ResearchResults: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [surveyDetails, setSurveyDetails] = useState<SurveyDetails | SurveyDetailsAnalyzed | null>(null);
  const [interviewInfo, setInterviewInfo] = useState<InterviewInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [socketOpen, setSocketOpen] = useState<boolean>(false);
  const [numberOfZones, setNumberOfZones] = useState<number>(0);

  const [activeTab, setActiveTab] = useState<string>('1');
  const { signOut, refreshToken } = useAuth();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<SelectDataFormat>({ value: 'presentation', label: 'Apresentação' });
  const [selectedOrderOption, setSelectedOrderOption] = useState<SelectDataFormat>({ value: 'biggerToSmaller', label: 'Maiores resultados' });

  const [generalChartRefs, setGeneralChartRefs] = useState<React.RefObject<HTMLDivElement>[]>([]);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);

  const [voters, setVoters] = useState<VoterData | null>(null);
  const [recommendedSample, setRecommendedSample] = useState<number | null>(null);

  const toggle = (tab: string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const handleChange = (option: SelectDataFormat | null, action: ActionMeta<SelectDataFormat>) => {
    if (option === null) return;
    
    setSelectedOption(option);
    if (option.value === 'ai-analysis') {
      sentDataForAIAnalysis();
    }
  };  

  const handleChangeOrderOption = (option: SelectDataFormat | null, action: ActionMeta<SelectDataFormat>)  => {
    if (option === null) return;
    setSelectedOrderOption(option);
  };

  const calculateSample = () => {
    if (surveyDetails) {
      const marginOfError = surveyDetails.research.margin_of_error;
      const reliability = Math.floor(surveyDetails.research.reliability);
  
      if (!voters || !marginOfError) {
        setRecommendedSample(null);
        return;
      }
  
      const N = voters.total_electorate;
      const zValues: Record<number, number> = {
        90: 1.645, // Valor z para 90% de confiança
        95: 1.96,  // Valor z para 95% de confiança
        99: 2.576  // Valor z para 99% de confiança
      };
  
      const z = zValues[reliability];
      if (z === undefined) {
        setRecommendedSample(null);
        return;
      }
  
      const d = marginOfError / 100;
      if (isNaN(d) || d <= 0) {
        setRecommendedSample(null);
        return;
      }
      
      const p = 0.5; // Assumindo p = 0.5 para máxima variação
      const numerator = N * z * z * p * (1 - p);
      const denominator = d * d * (N - 1) + z * z * p * (1 - p);
      
      const sampleSize = Math.round(numerator / denominator);
      
      setRecommendedSample(sampleSize);
    }
  };  

  const getTSEData = async() => {
    if (surveyDetails) {
        
      const { uf, city, reference_year } = surveyDetails.research;

      if (uf && city && reference_year) {
        try {
          const response = await api.get(`voters/${uf}/${city}/${reference_year}`);
          setVoters(response.data);
  
          const newToken = response.headers.token;
          if (newToken !== 'undefined') {
            refreshToken(newToken);
          }
        }catch(err: any) {
          if (err.response && err.response.status === 401) {
            signOut(navigate);
          }
          swal("Oppss!", `Erro ao obter dados do eleitorado: ${err}`, "error");
        }
      }
    }
  }

  useEffect(() => {
    calculateSample();
  }, [voters]);

  useEffect(() => {
    getTSEData();
  }, [surveyDetails]);

  useEffect(() => {
    if (Array.isArray(surveyDetails?.aggregatedData)) {
      const refs = surveyDetails.aggregatedData.map(() => createRef<HTMLDivElement>());
      setGeneralChartRefs(refs);
    }
  }, [surveyDetails]);

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        const response = await api.get<SurveyDetails>(`/interviews/survey-details/${id}`);
        
        setSurveyDetails(response.data);
        setInterviewInfo(response.data.interviewInfo);

        const newToken = response.headers.token;
        if (newToken !== 'undefined') {
          refreshToken(newToken);
        }
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          signOut(navigate);
        }
        swal("Oppss!", `Erro: ${err}`, "error");
      } finally {
        setLoading(false);
      }
    };
    if (!surveyDetails) {
      fetchSurveyDetails();
    }
  }, [
    id,
    signOut, 
    refreshToken, 
  ]);

  useEffect(() => {
    if (socketOpen && selectedOption.value === 'ai-analysis') {
      setupSocketListeners(
        (data) => setSurveyDetails(data),
        (error) => console.error('Erro no socket:', error)
      );
    }
    return () => {
      if (socket) {
        socket.off('update');
      }
    };
  }, [socketOpen, selectedOption.value]);

  if (loading) {
    return (
      <>
        <AnimationContainer animationType='loadingDots' visible={true}/>
        <div className="mt-8 text-center">
          <h3 className="text-center">Gerando análises...</h3>
          Aguarde, as análises são computacionalmente intensas. Isso pode demorar alguns minutos.
        </div>
      </>
    );
  }  

  if (!surveyDetails) {
    return <div>Não foi possível carregar os detalhes da pesquisa.</div>;
  }

  const sentDataForAIAnalysis = async () => {

    setNumberOfZones(surveyDetails.zonesData.length);

    try {
      const response = await api.post<SurveyDetails>('/ia-analysis/report', surveyDetails);

      if (response.status === 202) {
        setSocketOpen(true);

        if (surveyDetails){
          const newSurveyDetails: SurveyDetailsAnalyzed = {
            research: surveyDetails.research,
            aggregatedData: null,
            zonesData: [],
            urbanAggregate: null,
            ruralAggregate: null,
            interviewInfo: surveyDetails.interviewInfo,
            chi2AnalysisData: surveyDetails.chi2AnalysisData
          };
          setSurveyDetails(newSurveyDetails);
        }
      }

      const newToken = response.headers.token;
      if (newToken !== 'undefined') {
        refreshToken(newToken);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        signOut(navigate);
      }
      swal("Oppss!", `Erro: ${err}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const renderInfoCards = () => {
    if (!interviewInfo || !surveyDetails) return null;
  
    const { research } = surveyDetails;
    const formattedCityUf = `${research.city}, ${research.uf}`;
    const reliabilityLabels: { [key: number]: string } = {
      90: "90%",
      95: "95%",
      99: "99%",
    };
    
    // Assegurando que reliability seja um número inteiro
    const reliabilityValue = Math.floor(research.reliability);
    const reliabilityLabel = reliabilityLabels[reliabilityValue] || "95%";
  
    return (
      <>
        <Row>
          <Col lg="4" xl="4">
            <StyledCard>
              <StyledCardBody>
                <StyledCardTitle tag="h5">Definições Gerais</StyledCardTitle>
                <StyledCardText>Título: {research.title}</StyledCardText>
                <StyledCardText>Local: {formattedCityUf}</StyledCardText>
                <StyledCardText>Confiança alvo: {reliabilityLabel}</StyledCardText>
                <StyledCardText>Margem de Erro alvo: {research.margin_of_error}%</StyledCardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
          <Col lg="4" xl="4">
            <StyledCard>
              <StyledCardBody>
              <StyledCardTitle tag="h5">Duração e Status</StyledCardTitle>
              <StyledCardText>
                Pesquisa adicionada {format(new Date(research.createdAt), "PPPP 'às' p", { locale: ptBR })}
              </StyledCardText>
              <StyledCardText>
                Primeira entrevista realizada {format(new Date(interviewInfo.firstInterviewDate), "PPPP 'às' p", { locale: ptBR })}
              </StyledCardText>
              <StyledCardText>
                Última entrevista realizada {format(new Date(interviewInfo.lastInterviewDate), "PPPP 'às' p", { locale: ptBR })}
              </StyledCardText>
                <StyledCardText>Status: {research.isCompleted ? 'Concluída' : 'Em andamento'}</StyledCardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
          <Col lg="4" xl="4">
            <StyledCard>
              <StyledCardBody>
                <StyledCardTitle tag="h5">Entrevistas e Dados Eleitorais</StyledCardTitle>
                <StyledCardText>Tamanho eleitorado: {voters ? `${voters.total_electorate} pessoas` : 'obtendo...'}</StyledCardText>
                <StyledCardText>Ano de Referência para informações do eleitorado: {research.reference_year}</StyledCardText>
                <StyledCardText>Total de entrevistas realizadas: {interviewInfo.totalInterviews}</StyledCardText>
                <StyledCardText>Amostra necessária para o alvo: {recommendedSample ? recommendedSample : 'calculando...'}</StyledCardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
      </>
    );
  };  

  const getColor = (entry: Answer, max: number, min: number) => {
    // Lista de todas as condições específicas
    const specificConditions = [
      "Indeciso", "Indecisos", "Branco", "Brancos", "Nulo", "Nulos",
      "Branco/Nulo", "Brancos/Nulos", "Branco e Nulo", "Brancos e Nulos",
      "Não sabe", "Não sabem", "Não Opinou", "Não Opinaram",
      "Não sabe/Não Opinou", "Não sabem/Não Opinaram"
    ];
  
    // Normaliza o texto da resposta para a comparação
    const normalizedAnswerText = entry.answerText.trim().toLowerCase();
  
    // Verifica se o texto normalizado da resposta corresponde a alguma das condições específicas
    if (specificConditions.map(condition => condition.toLowerCase()).includes(normalizedAnswerText)) {
      return 'rgb(255, 255, 0)'; // Cor amarela para condições específicas
    }
  
    if (max === min) return 'rgb(0, 0, 255)'; // Azul se todos os valores forem iguais
    const ratio = (entry.percentage - min) / (max - min);
    const blue = Math.round(ratio * 255);
    const red = 255 - blue;
    return `rgb(${red}, 0, ${blue})`;
  };

  const sortAnswers = (answers: Answer[]) => {
    switch (selectedOrderOption.value) {
      case 'biggerToSmaller':
        return [...answers].sort((a, b) => b.percentage - a.percentage);
      case 'defaultOrder':
      default:
        return [...answers].sort((a, b) => a.answer_order - b.answer_order);
    }
  };

  const VotersProfileCharts = () => {
    if (!voters) return null;
  
    const genderData = Object.keys(voters.gender_distribution).map((key) => ({
      name: key,
      value: voters.gender_distribution[key],
    }));
  
    const educationData = Object.keys(voters.education_levels).map((key) => ({
      name: key,
      value: voters.education_levels[key],
    }));
  
    const ageData = Object.keys(voters.age_ranges).map((key) => ({
      name: key,
      value: voters.age_ranges[key],
    }));
  
    const colors = ['#800080', '#FFA500', '#FFBB28', '#FF8042'];
    const fontSize = '13px';
  
    const renderCustomizedLabel = ({ percent, value }: { percent: number; value: number }) => {
      return `${(percent * 100).toFixed(2)}% (${value})`;
    };
  
    const tooltipFormatter = (value: number, name: string) => {
      const total = genderData.reduce((acc, item) => acc + item.value, 0);
      const percent = (value / total) * 100;
      return [`${percent.toFixed(2)}% (${value})`, 'Quantidade'];
    };    
  
    const customTickFormatter = (value: number) => `${value}%`;
  
    return (
      <div>
        <Card className="mb-3" style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>
          <CardBody>
            <CardTitle tag="h4" style={{ color: '#FFFFFF', fontSize }}>
              Distribuição de Gênero
            </CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={genderData}
                  fill="#800080"
                  label={renderCustomizedLabel}
                >
                  {genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{ backgroundColor: '#555555', borderColor: '#777777', color: '#FFFFFF', fontSize }}
                />
                <Legend
                  wrapperStyle={{ color: '#FFFFFF', fontSize }}
                  formatter={(value: string) => <span style={{ color: '#FFFFFF' }}>{value}</span>}
                />
              </PieChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
  
        <Card className="mb-3" style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>
          <CardBody>
            <CardTitle tag="h4" style={{ color: '#FFFFFF', fontSize }}>
              Níveis de Educação
            </CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={educationData} style={{ backgroundColor: '#333333' }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#555555" />
                <XAxis dataKey="name" stroke="#FFFFFF" style={{ fontSize }} />
                <YAxis stroke="#FFFFFF" style={{ fontSize }} tickFormatter={customTickFormatter} />
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{ backgroundColor: '#555555', borderColor: '#777777', color: '#FFFFFF', fontSize }}
                />
                <Legend
                  wrapperStyle={{ color: '#FFFFFF', fontSize }}
                  formatter={() => 'Quantidade'}
                />
                <Bar dataKey="value" fill="#FFFFFF">
                  {educationData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
  
        <Card className="mb-3" style={{ backgroundColor: '#333333', color: '#FFFFFF' }}>
          <CardBody>
            <CardTitle tag="h4" style={{ color: '#FFFFFF', fontSize }}>
              Faixas Etárias
            </CardTitle>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={ageData} style={{ backgroundColor: '#333333' }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#555555" />
                <XAxis dataKey="name" stroke="#FFFFFF" style={{ fontSize }} />
                <YAxis stroke="#FFFFFF" style={{ fontSize }} tickFormatter={customTickFormatter} />
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{ backgroundColor: '#555555', borderColor: '#777777', color: '#FFFFFF', fontSize }}
                />
                <Legend
                  wrapperStyle={{ color: '#FFFFFF', fontSize }}
                  formatter={() => 'Quantidade'} // Substitui "value" por "Quantidade"
                />
                <Bar dataKey="value" fill="#FFFFFF">
                  {ageData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
      </div>
    );
  };

  // Renderização dos gráficos de barra para dados gerais
  const renderGeneralCharts = () => {
    if (!surveyDetails) return null;

    if ('aggregatedData' in surveyDetails && surveyDetails.aggregatedData) {
      if (Array.isArray(surveyDetails.aggregatedData)) {
        return (
          <div>
            {surveyDetails.aggregatedData.map((data: AggregatedData, index: number) => {
              const values = data.answers.map(answer => answer.percentage);
              const maxVal = Math.max(...values);
              const minVal = Math.min(...values);
    
              const sortedAnswers = sortAnswers(data.answers);
    
              return (
                <div key={index} ref={generalChartRefs[index]} className="mb-4">
                  <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
                    <CardBody>
                      <CardTitle tag="h4" style={{ color: 'white' }}>{data.questionText}</CardTitle>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={sortedAnswers} style={{ backgroundColor: '#333' }}>
                          <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                          <XAxis dataKey="answerText" stroke="white" />
                          <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="white" />
                          <Tooltip contentStyle={{ backgroundColor: '#555', borderColor: '#777', color: 'white' }} />
                          <Legend wrapperStyle={{ color: 'white' }} />
                          <Bar dataKey="percentage" name="Percentual">
                            {sortedAnswers.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getColor(entry, maxVal, minVal)} />
                            ))}
                            <LabelList dataKey="percentage" position="top" style={{ fill: 'white' }} formatter={(value: number) => `${value}%`} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </CardBody>
                  </Card>
                  <RenderQuestionTable data={data} />
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div>
            <div className="text-justify">
              {surveyDetails.aggregatedData.IATitle && <h5 className="text-white"><FaBrain/><i> Análise de Inteligência Artificial Generativa </i></h5>}
              {surveyDetails.aggregatedData.IATitle && <h5 className="text-white">{surveyDetails.aggregatedData.IATitle}</h5>}
              {surveyDetails.aggregatedData.IAAnalysis && <div className="text-white" dangerouslySetInnerHTML={{ __html: surveyDetails.aggregatedData.IAAnalysis }} />}
              <br/>
            </div>
            {surveyDetails.aggregatedData.data.map((data: AggregatedData, index: number) => {
              const values = data.answers.map(answer => answer.percentage);
              const maxVal = Math.max(...values);
              const minVal = Math.min(...values);
    
              const sortedAnswers = sortAnswers(data.answers);
    
              return (
                <div key={index} ref={generalChartRefs[index]} className="mb-4">
                  <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
                    <CardBody>
                      <CardTitle tag="h4" style={{ color: 'white' }}>{data.questionText}</CardTitle>
                      <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={sortedAnswers} style={{ backgroundColor: '#333' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="answerText" stroke="white"/>
                          <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="white" />
                          <Tooltip contentStyle={{ backgroundColor: '#555', borderColor: '#777', color: 'white' }} />
                          <Legend wrapperStyle={{ color: 'white' }} />
                          <Bar dataKey="percentage" name="Percentual">
                            {sortedAnswers.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getColor(entry, maxVal, minVal)} />
                            ))}
                            <LabelList dataKey="percentage" position="top" style={{ fill: 'white' }} formatter={(value: number) => `${value}%`} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </CardBody>
                  </Card>
                  <RenderQuestionTable data={data} />
                </div>
              );
            })}
          </div>
        );
      }
    } else {
      if (socketOpen && selectedOption.value === 'ai-analysis') {
        return (
          <>
            <AnimationDelimitedArea animationType='AIv2' visible={true}/>
          </>
        );
      }
    }
  };

  const renderZoneChartsAndTables = (): JSX.Element | null => {
    if (!surveyDetails || !surveyDetails.zonesData) return null;
  
    const isAIAnalysisSelected = socketOpen && selectedOption.value === 'ai-analysis';
    const isDataLoading = isAIAnalysisSelected && surveyDetails.zonesData.length < numberOfZones;
  
    return (
      <>
        {surveyDetails.zonesData.map((zone, index) => {
  
          return (
            <div key={zone.zoneId} className="mb-4">
              <h3 className="text-center text-white">{zone.zoneName} ({zone.zoneType})</h3>
  
              {zone.IATitle && (
                <div className="text-justify">
                  <h5 className="text-white" dangerouslySetInnerHTML={{ __html: zone.IATitle }}></h5>
                  <div className="text-white" dangerouslySetInnerHTML={{ __html: zone.IAAnalysis || "" }}></div>
                </div>
              )}
  
              {zone.aggregatedData.map((questionData, questionIndex) => {
                const values = questionData.answers.map(answer => answer.percentage);
                const maxVal = Math.max(...values);
                const minVal = Math.min(...values);
  
                const sortedAnswers = sortAnswers(questionData.answers);
  
                return (
                  <div key={index} ref={generalChartRefs[index]} className="mb-4">
                    <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
                      <CardBody>
                        <CardTitle tag="h4" style={{ color: 'white' }}>{questionData.questionText}</CardTitle>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart data={sortedAnswers} style={{ backgroundColor: '#333' }}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                            <XAxis dataKey="answerText" stroke="white" />
                            <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="white" />
                            <Tooltip contentStyle={{ backgroundColor: '#555', borderColor: '#777', color: 'white' }} />
                            <Legend wrapperStyle={{ color: 'white' }} />
                            <Bar dataKey="percentage" name="Percentual">
                              {sortedAnswers.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getColor(entry, maxVal, minVal)} />
                              ))}
                              <LabelList dataKey="percentage" position="top" style={{ fill: 'white' }} formatter={(value: number) => `${value}%`} />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </CardBody>
                    </Card>
                    <RenderQuestionTable data={questionData} />
                  </div>
                );
              })}
            </div>
          );
        })}
        {isDataLoading && <AnimationDelimitedArea animationType='AIv2' visible={true} />}
      </>
    );
  };  

  const UrbanAIAnalysis = () => {
    if (socketOpen && selectedOption.value === 'ai-analysis') {

      if (surveyDetails.urbanAggregate === null) {
        return (
          <div className="text-center">
            <AnimationDelimitedArea animationType='AIv2' visible={true}/>
          </div>
        );
      }

      if (surveyDetails.urbanAggregate.IATitle && surveyDetails.urbanAggregate.IAAnalysis) {
        return (
          <div className="text-justify">
            {surveyDetails.urbanAggregate?.IATitle && <h5 className="text-white"><FaBrain/><i> Análise de Inteligência Artificial Generativa </i></h5>}
            {surveyDetails.urbanAggregate?.IATitle && <h5 className="text-white">{surveyDetails.urbanAggregate?.IATitle}</h5>}
            {surveyDetails.urbanAggregate?.IAAnalysis && <div className="text-white" dangerouslySetInnerHTML={{ __html: surveyDetails.urbanAggregate?.IAAnalysis }} />}
          </div>
        );
      }
    }
    return (
      <></>
    );
  }

  const RuralAIAnalysis = () => {
    if (socketOpen && selectedOption.value === 'ai-analysis') {

      if (surveyDetails.ruralAggregate === null) {
        return (
          <div className="text-center">
            <AnimationDelimitedArea animationType='AIv2' visible={true}/>
          </div>
        );
      }

      if (surveyDetails.ruralAggregate.IATitle && surveyDetails.ruralAggregate.IAAnalysis) {
        return (
          <div className="text-justify">
            {surveyDetails.ruralAggregate?.IATitle && <h5 className="text-white"><FaBrain/><i> Análise de Inteligência Artificial Generativa </i></h5>}
            {surveyDetails.ruralAggregate?.IATitle && <h5 className="text-white">{surveyDetails.ruralAggregate?.IATitle}</h5>}
            {surveyDetails.ruralAggregate?.IAAnalysis && <div className="text-white" dangerouslySetInnerHTML={{ __html: surveyDetails.ruralAggregate?.IAAnalysis }} />}
          </div>
        );
      }
      return (
        <></>
      );
    }
    return null;
  }

  const renderUrbanChartsAndTables = () => {
    if (!surveyDetails.urbanAggregate?.aggregatedData) return null;
  
    return surveyDetails.urbanAggregate.aggregatedData.map((data, index) => {
      const values = data.answers.map(answer => answer.percentage);
      const maxVal = Math.max(...values);
      const minVal = Math.min(...values);
  
      const sortedAnswers = sortAnswers(data.answers); // Usando a função sortAnswers para ordenar conforme selectedOrderOption
  
      return (
        <div key={index} className="mb-4">
          <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
            <CardBody>
              <CardTitle tag="h4" style={{ color: 'white' }}>{`${data.questionText} (zona urbana)`}</CardTitle>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={sortedAnswers} style={{ backgroundColor: '#333' }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="answerText" stroke="white" />
                  <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="white" />
                  <Tooltip formatter={(value) => {
                    return typeof value === 'number' ? `${value.toFixed(2)}%` : value;
                    }} 
                  />
                  <Legend wrapperStyle={{ color: 'white' }} />
                  <Bar dataKey="percentage" name="Percentual">
                    {sortedAnswers.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry, maxVal, minVal)} />
                    ))}
                    <LabelList dataKey="percentage" position="top" style={{ fill: 'white' }} formatter={(value: number) => `${value}%`} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
          <RenderQuestionTable data={data} />
        </div>
      );
    });
  };  

  const renderRuralChartsAndTables = () => {
    if (!surveyDetails.ruralAggregate?.aggregatedData) return null;
  
    return surveyDetails.ruralAggregate.aggregatedData.map((data, index) => {
      const values = data.answers.map(answer => answer.percentage);
      const maxVal = Math.max(...values);
      const minVal = Math.min(...values);
  
      const sortedAnswers = sortAnswers(data.answers); // Ordenação baseada no estado selectedOrderOption
  
      return (
        <div key={index} className="mb-4">
          <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
            <CardBody>
            <CardTitle tag="h4" style={{ color: 'white' }}>{`${data.questionText} (zona rural)`}</CardTitle>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={sortedAnswers} style={{ backgroundColor: '#333' }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="answerText" stroke="white" />
                  <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} stroke="white" />
                  <Tooltip formatter={(value) => typeof value === 'number' ? `${value.toFixed(2)}%` : value} />
                  <Legend />
                  <Bar dataKey="percentage" name="Percentual">
                    {sortedAnswers.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry, maxVal, minVal)} />
                    ))}
                    <LabelList dataKey="percentage" position="top" style={{ fill: 'white' }} formatter={(value: number) => `${value}%`} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
          <RenderQuestionTable data={data} />
        </div>
      );
    });
  };  

  // Renderização do gráfico de entrevistas por agente
  const renderAgentChart = () => {
    if (!interviewInfo || selectedOption.value === 'presentation') return null;
    
    if (!interviewInfo) return null;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={interviewInfo.interviewsByAgent}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="agentName" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#82ca9d" name="Entrevistas" />
        </BarChart>
      </ResponsiveContainer>
    );
  };
  
  type QuestionTableProps = {
    data: AggregatedData;
  };
  
  const RenderQuestionTable = ({ data }: QuestionTableProps) => {
    let sortedAnswers = [...data.answers];
    
    if (selectedOrderOption.value === 'biggerToSmaller') {
      sortedAnswers.sort((a, b) => b.percentage - a.percentage);
    }
  
    return (
      <>
        <Table striped bordered hover responsive className="table-dark">
          <thead>
            <tr>
              <th>Resposta</th>
              {selectedOption.value === 'detailed' && <th>Contagem</th>}
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            {sortedAnswers.map((answer) => (
              <tr key={answer.answerId}>
                <td>{answer.answerText}</td>
                {selectedOption.value === 'detailed' && <td>{answer.count}</td>}
                <td>{`${answer.percentage.toFixed(2)}%`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  const exportToExcel = (surveyDetails: SurveyDetails | SurveyDetailsAnalyzed, interviewInfo: InterviewInfo) => {
    const wb = utils.book_new();
    let data: (string | number | boolean)[][] = [];
  
    // Adiciona dados de informações gerais
    data.push(["Informações Gerais"]);
    data.push(["Primeira Entrevista", format(new Date(interviewInfo.firstInterviewDate), 'PPpp')]);
    data.push(["Última Entrevista", format(new Date(interviewInfo.lastInterviewDate), 'PPpp')]);
    data.push(["Total de Entrevistas", interviewInfo.totalInterviews]);
    data.push(["Título da Pesquisa", surveyDetails.research.title]);
    data.push(["Data de Criação", format(new Date(surveyDetails.research.createdAt), 'PPpp')]);
    data.push(["Status da Pesquisa", surveyDetails.research.isCompleted ? 'Concluída' : 'Em andamento']);
    data.push([""]); // Linha em branco como separador
  
    // Adiciona dados agregados
    const addAggregatedDataToSheet = (aggregatedData: AggregatedData[] | AggregatedDataProps | null) => {
      if (!aggregatedData) return;
  
      if ('data' in aggregatedData) {
        aggregatedData.data.forEach(aggregated => {
          data.push([aggregated.questionText]);
          data.push(["Texto da Resposta", "Contagem", "Percentual"]);
          aggregated.answers.forEach(ans => {
            data.push([ans.answerText, ans.count, `${ans.percentage.toFixed(2)}%`]);
          });
          data.push([""]); // Linha em branco como separador
        });
      } else {
        aggregatedData.forEach(aggregated => {
          data.push([aggregated.questionText]);
          data.push(["Texto da Resposta", "Contagem", "Percentual"]);
          aggregated.answers.forEach(ans => {
            data.push([ans.answerText, ans.count, `${ans.percentage.toFixed(2)}%`]);
          });
          data.push([""]); // Linha em branco como separador
        });
      }
    };
    addAggregatedDataToSheet(surveyDetails.aggregatedData);
  
    // Adiciona entrevistas por agente
    data.push(["Entrevistas por Agente"]);
    data.push(["Nome do Agente", "Número de Entrevistas"]);
    interviewInfo.interviewsByAgent.forEach(agent => {
      data.push([agent.agentName, agent.count]);
    });
    data.push([""]); // Linha em branco como separador
  
    // Adiciona dados de zonas
    const addZoneData = (zoneData: ZoneData | UrbanRuralAggregateData, zoneType: string) => {
      zoneData.aggregatedData.forEach(questionData => {
        data.push([`${zoneType} - ${questionData.questionText}`]);
        data.push(["Resposta", "Percentual", "Contagem"]);
        questionData.answers.forEach(ans => {
          data.push([ans.answerText, `${ans.percentage.toFixed(2)}%`, ans.count]);
        });
        data.push([""]); // Linha em branco como separador
      });
    };
  
    if (surveyDetails.urbanAggregate) {
      addZoneData(surveyDetails.urbanAggregate, 'Urbana');
    }
  
    if (surveyDetails.ruralAggregate) {
      addZoneData(surveyDetails.ruralAggregate, 'Rural');
    }
  
    surveyDetails.zonesData.forEach(zone => addZoneData(zone, zone.zoneName));
  
    let maxWidths: number[] = [];

    data.forEach(row => {
      row.forEach((cell, index) => {
        const cellLength = cell.toString().length;
        maxWidths[index] = Math.max(maxWidths[index] || 0, cellLength);
      });
    });
  
    const ws = utils.aoa_to_sheet(data);
  
    // Ajusta a largura das colunas
    ws['!cols'] = maxWidths.map(w => ({ wch: w + 2 }));
  
    utils.book_append_sheet(wb, ws, 'Dados Completos');
  
    // Finaliza a criação do livro e inicia o download
    const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, 'Detalhes_da_Pesquisa.xlsx');
  };

  const addIaAnalysisPage = (pdf: jsPDF, titulo: string, texto: string): void => {
    const pageHeight: number = pdf.internal.pageSize.getHeight();
    const pageWidth: number = pdf.internal.pageSize.getWidth();
  
    const alturaCabecalho: number = 41.14; // Altura do cabeçalho adicionada à página
    const espacoAposCabecalho: number = 10; // Espaço após o cabeçalho antes do início do texto
  
    // Altura estimada de uma linha de texto
    const lineHeight: number = 10; // Ajuste conforme a fonte e tamanho que você está usando
    const padding: number = 20; // Espaço adicional para não começar exatamente do topo
  
    // Calcula quantas linhas o título e texto ocuparão
    const linhasTitulo: number = 1; // Título geralmente ocupa 1 linha
    const linhasTexto: number = pdf.splitTextToSize(texto, pageWidth - 40).length;
  
    // Altura total ocupada pelo texto
    const textoAlturaTotal: number = lineHeight * (linhasTitulo + linhasTexto) + padding;
  
    // Ajusta a altura disponível para o texto, subtraindo a altura do cabeçalho
    const alturaDisponivel: number = pageHeight - alturaCabecalho - espacoAposCabecalho;
  
    // Calcula a posição inicial y para centralizar verticalmente, considerando o cabeçalho
    const startY: number = ((alturaDisponivel - textoAlturaTotal) / 2) + alturaCabecalho + espacoAposCabecalho;
  
    // Adiciona o título
    pdf.setFontSize(20);
    pdf.setFont('bold');
    const larguraTitulo: number = pdf.getStringUnitWidth(titulo) * pdf.getFontSize() / pdf.internal.scaleFactor;
    const tituloX: number = (pageWidth - larguraTitulo) / 2;
    pdf.text(titulo, tituloX, startY);
  
    // Adiciona o texto da análise de IA abaixo do título
    pdf.setFontSize(14);
    pdf.setFont('normal');
    const linhas: string[] = pdf.splitTextToSize(texto, pageWidth - 40);
    pdf.text(linhas, 20, startY + lineHeight + 5); // Um pequeno ajuste para espaçamento entre o título e o texto
  };

  const addFooter = (pdf: jsPDF, isPrimeiraPagina: boolean): void => {
    const footerText = isPrimeiraPagina ? "Sistema de pesquisas e entrevistas com a qualidade Publisol - Soluções Públicas | publisol.com.br v2.0.0" : "Publisol pesquisas v2.0.0";
    const pageWidth: number = pdf.internal.pageSize.getWidth();
    const posY: number = pdf.internal.pageSize.getHeight() - 2; // Ajuste a posição Y conforme necessário
  
    pdf.setFontSize(10);
    pdf.setTextColor(255, 255, 255);
    const textWidth: number = pdf.getStringUnitWidth(footerText) * pdf.getFontSize() / pdf.internal.scaleFactor;
    const textX: number = (pageWidth - textWidth) / 2;
    pdf.text(footerText, textX, posY);
  
    if (isPrimeiraPagina) {
      // Adiciona a logo acima do texto no rodapé da primeira página
      const logoImg = logoWhite; 
      const logoWidth: number = 24.7; 
      const logoHeight: number = 10;
      const logoX: number = (pageWidth - logoWidth) / 2;
      const logoY: number = posY - logoHeight - 5; // Posiciona a logo acima do texto com um pequeno espaço
      pdf.addImage(logoImg, 'PNG', logoX, logoY, logoWidth, logoHeight);
    }
  };

  const savePDF = async () => {
    setIsCreatingPdf(true);
  
    try {
      const response = await api.get(`/interviews/report-pdf/${id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report_${id}.pdf`);
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
      setIsCreatingPdf(false);
    } catch (error) {
      setIsCreatingPdf(false);
      swal("Oppss!", `Erro: ${error}`, "error");
    }
  };

  const Chi2AnalysisReport = () => {
    return (
      <Container fluid>
        <Card className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
          <CardBody>
            <ReactMarkdown
              components={{
                h2: ({node, ...props}) => <StyledHeading {...props} />
              }}
            >
              {surveyDetails?.chi2AnalysisData.report}
            </ReactMarkdown>
          </CardBody>
        </Card>
      </Container>
    );
  }

  const Chi2AnalysisReferences = () => {
    const chi2Data = surveyDetails?.chi2AnalysisData.references[1];
  
    if (!chi2Data) return <div>Carregando dados...</div>;
  
    return (
      <Container fluid>
        <h2 className="text-center text-white mb-4 mt-4">Referências <small>(análise de chi-quadrado)</small> </h2>
        {surveyDetails.chi2AnalysisData.references.map((chi2Data, index) => (
          <Card key={index} className="mb-3" style={{ backgroundColor: '#333', color: 'white' }}>
            <CardBody>
              <p className="text-white text-justify">
                <strong>(ID: { chi2Data.id }) <br/></strong>
                <strong>{chi2Data.percentage_of_a_given_a}%</strong> daqueles que declararam 
                <strong> "{chi2Data.answer_a}"</strong> para a pergunta 
                <strong> "{chi2Data.question_a}"</strong> também declararam
                <strong> "{chi2Data.answer_b}"</strong> para a pergunta
                <strong> "{chi2Data.question_b}" </strong>.
                Isso corresponde a <strong>{chi2Data.percentage_of_both_in_total}% (ambas), {chi2Data.percentage_of_a_in_total}% (A), {chi2Data.percentage_of_b_in_total}% (B) </strong>
                do total da amostra, respectivamente. 
              </p>
              <CardTitle tag="h4" style={{ color: 'white' }}>Diagrama de Venn</CardTitle>
              <VennDiagram
                totalA={chi2Data.occurrences_of_a_in_total}
                totalB={chi2Data.occurrences_of_b_in_total}
                occurrences={chi2Data.occurrences_of_both_in_total}
                percentageOfAInA={chi2Data.percentage_of_a_in_total}
                percentageOfBInB={chi2Data.percentage_of_b_in_total}
                percentageOfBoth={chi2Data.percentage_of_both_in_total}
                percentageOfAGivenA={chi2Data.percentage_of_a_given_a}
                questionA={chi2Data.question_a}
                answerA={chi2Data.answer_a}
                questionB={chi2Data.question_b}
                answerB={chi2Data.answer_b}
                width={500}
                height={300}
              />
              <p><small>*representações do diagrama podem não ser completamente precisas para privilegiar a visualização dos dados, a exemplo de quando um círculo deveria se sobrepor totalmente a outro (100% de intersecção), escolhemos por exibir uma intersecção parcial.</small></p>
              <DataLegend
                items={[
                  {
                    color: '#a600c3',
                    text: (
                      <>
                        Grupo A: responde <strong>{chi2Data.answer_a}</strong> para <strong>{chi2Data.question_a}</strong>
                      </>
                    ),
                  },
                  {
                    color: '#cf6100',
                    text: (
                      <>
                        Grupo B: responde <strong>{chi2Data.answer_b}</strong> para <strong>{chi2Data.question_b}</strong>
                      </>
                    ),
                  },
                  { color: 'white', text: 'Interseção: A e B' },
                ]}
              />
              <h4 className="text-white mt-4">Porcentagem de A em B</h4>
              <ResponsiveContainer className="align-items-center" width="100%" height={150}>
                <BarChart
                  data={[{ name: 'Se A então B', value: chi2Data.percentage_of_a_given_a }]}
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[0, 100]} />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#9e3d3d" label={{ position: 'top' }} />
                </BarChart>
              </ResponsiveContainer>         
              <p className="text-white text-justify mt-3">
                Inversamente, 
                <strong> {chi2Data.percentage_of_b_given_b}%</strong> daqueles que declararam 
                <strong> "{chi2Data.answer_b}"</strong> para <strong>"{chi2Data.question_b} </strong>
                também declararam <strong>"{chi2Data.answer_a}"</strong> para <strong>"{chi2Data.question_a}"</strong>.
              </p>
              <h4 className="text-white mt-4">Porcentagem de B em A</h4>
              <ResponsiveContainer className="align-items-center" width="100%" height={150}>
                <BarChart
                  data={[{ name: 'Se B então A', value: chi2Data.percentage_of_b_given_b }]}
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={[0, 100]} />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="value" fill="#8c4f4f" label={{ position: 'top' }} />
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        ))}
      </Container>
    );

  };
  
  return (
    <React.Fragment>
      <GlobalStyle/>
      <DefaultNavbar />
      <div data-researches-main className="header pb-8 pt-5 pt-md-4">
        <Container fluid className="pt-4 px-4">
          <DarkNav tabs>
            <DarkNavItem>
              <DarkNavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => { toggle('1'); }}
              >
                Dados Agregados
              </DarkNavLink>
            </DarkNavItem>
            <DarkNavItem>
              <DarkNavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => { toggle('2'); }}
              >
                Análise de Correlação (IA)
              </DarkNavLink>
            </DarkNavItem>
            <DarkNavItem>
              <DarkNavLink
                className={activeTab === '3' ? 'active' : ''}
                onClick={() => { toggle('3'); }}
              >
                Perfil do Eleitorado
              </DarkNavLink>
            </DarkNavItem>
          </DarkNav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <HeaderContainer className="d-print-none" fluid>
              <Row>
                <Col md="5" lg="5" className="mb-3 mb-lg-0">
                  <Label htmlFor="visualization-select" className="text-white"><strong>Modo de Visualização:</strong></Label>
                  <DarkSelect
                    id="visualization-select"
                    defaultValue={selectedOption}
                    placeholder="Selecione um tipo de visualização"
                    noOptionsMessage={() => "Nenhuma opção encontrada :("}
                    onChange={handleChange}
                    options={options}
                  />
                </Col>
                <Col md="5" lg="5" className="mb-3 mb-lg-0">
                  <Label htmlFor="ordenation-select" className="text-white"><strong>Ordenar respostas por:</strong></Label>
                  <DarkSelect
                    id="ordenation-select"
                    defaultValue={selectedOrderOption}
                    placeholder="Selecione um tipo de ordenação"
                    noOptionsMessage={() => "Nenhuma opção encontrada :("}
                    onChange={handleChangeOrderOption}
                    options={orderOption}
                  />
                </Col>
                <Col md="2" lg="2" className="d-flex align-items-center">
                  <StyledButton disabled={isCreatingPdf} size="sm" color="secondary" onClick={savePDF} className="mr-2">
                  { isCreatingPdf
                    ? <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Criando PDF...
                      </>
                    : <><IoCloudDownloadOutline size={20} className='mr-1' /> Salvar PDF</>
                  }
                    
                  </StyledButton>
                  <StyledButton
                    disabled={isCreatingPdf}
                    size="sm" 
                    color="secondary" 
                    onClick={() => {
                      if (interviewInfo && surveyDetails) {
                        exportToExcel(surveyDetails, interviewInfo);
                      } else {
                        console.error("Dados não estão carregados.");
                      }
                    }}
                  >
                    <IoCloudDownloadOutline size={20} className='mr-1' /> Exportar para xlsx
                  </StyledButton>
                </Col>
              </Row>
            </HeaderContainer>

            {
              interviewInfo?.totalInterviews && recommendedSample && recommendedSample > interviewInfo.totalInterviews &&
              <Alert className="alert-danger">
                Atenção! Essa pesquisa requer pelo menos <strong>{` ${recommendedSample}`}</strong> entrevistas, mas foram realizadas apenas <strong>{` ${interviewInfo.totalInterviews}`}</strong>. Os resultados não são confiáveis enquanto a amostra necessária não for atingida ou superada.
              </Alert>
            }

            {/* Info Cards Section */}
            <section className="mb-5">
              <h2 className="text-center text-white mt-6">Informações Gerais</h2>
              <div className="info-cards-container shadow-sm p-3 bg-dark rounded">
                {renderInfoCards()}
              </div>
            </section>

            <section className="mb-5">
              <h2 className="text-center text-white mb-4">Geral</h2>
              <div className="general-charts-container shadow-sm p-3 bg-dark rounded">
                <Row className="text-center">
                  <div className="col-12">
                    {renderGeneralCharts()}
                  </div>
                </Row>
              </div>

              <h2 className="text-center text-white mb-4">Somente Zona Urbana</h2>
              
              <div className="urban-charts-container shadow-sm p-3 bg-dark rounded">
                <UrbanAIAnalysis/>
                { renderUrbanChartsAndTables()}
              </div>
              
              <h2 className="text-center text-white mb-4">Somente Zona Rural</h2>
              <div className="rural-charts-container shadow-sm p-3 mb-5 bg-dark rounded">
                <RuralAIAnalysis/>
                { renderRuralChartsAndTables()}
              </div>
            </section>
      
            {/* Zone Charts Section */}
            <section className="mb-5">
              <h2 className="text-center text-white mb-4">Por localidade</h2>
              <div className="zone-charts-container shadow-sm p-3 mb-5 bg-dark rounded">
                <Row className="text-center">
                  <div className="col-12">
                    {renderZoneChartsAndTables()}
                  </div>
                </Row>
              </div>
            </section>
      
            {/* Agent Chart Section */}
            {selectedOption.value === 'detailed' && (
              <section className="mb-5">
                <h2 className="text-center mb-4">Entrevistas por Agente</h2>
                <div className="agent-charts-container shadow-sm p-3 mb-5 bg-dark rounded">
                  <Row className="text-center">
                    <div className="col-12">
                      {renderAgentChart()}
                    </div>
                  </Row>
                </div>
              </section>
            )}
            </TabPane>
            <TabPane tabId="2">
              {
                activeTab === "2"
                ? 
                  <section className="mb-5">
                    <div className="general-charts-container shadow-sm p-3 mb-5 bg-dark rounded">
                      <Row className="text-justify">
                        <Col md="12">
                          <Chi2AnalysisReport />
                        </Col>
                      </Row>
                      <Row className="text-center">
                        <div className="col-12">
                          <Chi2AnalysisReferences/>
                        </div>
                      </Row>
                    </div>
                  </section>
                : null
              }
            </TabPane>
            <TabPane tabId="3">
              {
                activeTab === "3"
                ? 
                  <section className="mb-5">
                    <h2 className="text-center text-white mb-4 mt-4">Perfil do Eleitorado em {`${surveyDetails.research.city},${surveyDetails.research.uf}`} </h2>
                    <div className="general-charts-container shadow-sm p-3 mb-5 bg-dark rounded">
                      <Row className="text-center">
                        <div className="col-12">
                          <VotersProfileCharts/>
                        </div>
                      </Row>
                    </div>
                  </section>
                : null
              }
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <GradientFooter />
    </React.Fragment>
  );
    
};

export default ResearchResults;