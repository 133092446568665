import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AnimatedPage from '../components/AnimatedPage';

import useAuth from '../hooks/useAuth';

import Login from '../views/Users/Login';
import Users from '../views/Users/Users';
import MyProfile from '../views/Users/MyProfile';
import Researches from '../views/Researches';
import ResearchAgent from '../views/ResearchAgents';
import ResearchResults from '../views/ResearchResults';
import AutoInterview from '../views/PublicAutoInterview';
import Page404 from '../views/Errors/404';

function CustomRoute({ isPrivate, element }: { isPrivate?: boolean, element: React.ReactElement }) {
  const { authenticated } = useAuth();

  if (isPrivate && !authenticated) {
    return <Navigate to="/" replace />;
  }

  return element;
}

const RoutesComponent = () => {
  const location = useLocation();
  const { authenticated } = useAuth();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
      <Route
          path='/'
          element={
            authenticated ? (
              <Navigate to="/pesquisas" replace />
            ) : (
              <AnimatedPage><Login /></AnimatedPage>
            )
          }
        />
        <Route path='/entrevista/:id' element={<CustomRoute isPrivate={false} element={<AnimatedPage><AutoInterview /></AnimatedPage>} />} />
        <Route path='/usuarios' element={<CustomRoute isPrivate={true} element={<AnimatedPage><Users /></AnimatedPage>} />} />
        <Route path='/agentes-de-pesquisas' element={<CustomRoute isPrivate={true} element={<AnimatedPage><ResearchAgent /></AnimatedPage>} />} />
        <Route path='/resultados/:id' element={<CustomRoute isPrivate={true} element={<AnimatedPage><ResearchResults /></AnimatedPage>} />} />
        <Route path='/meu-perfil' element={<CustomRoute isPrivate={true} element={<AnimatedPage><MyProfile /></AnimatedPage>} />} />
        <Route path='/pesquisas' element={<CustomRoute isPrivate={true} element={<AnimatedPage><Researches /></AnimatedPage>} />} />
        <Route path='*' element={<AnimatedPage><Page404 /></AnimatedPage>} />
      </Routes>
    </AnimatePresence>
  );
}

export default RoutesComponent;