import styled from 'styled-components';

interface StyledBadgeProps {
  color: string;
}

export const StyledBadge = styled.span<StyledBadgeProps>`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  color: ${(props) => (props.color === 'white' ? 'black' : 'white')};
`;
