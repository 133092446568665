import React from 'react';

import logoWhite from '../../../assets/img/logo/logo-publisol-white.png';

const SimpleFooter = () => {

  return (
    <footer className="py-4 text-white-50 mt-auto">
      <div className="container text-center">
        <img alt="Logo" width={120} src={logoWhite} /> <br/>
        <small>
          2024 &copy; Publisol - Soluções Públicas | Publisol Pesquisas 2.7.0
        </small>
      </div>
    </footer>
  );

};

export default SimpleFooter;