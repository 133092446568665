// socketService.ts
import io, { Socket } from 'socket.io-client';

let socket: Socket | null = null;

const connectSocket = () => {
  if (!socket) {
    const env = process.env.NODE_ENV;
    const baseURL = env === 'development' ? 'http://localhost:3333' : 'https://api.pesquisas.publisol.com.br:8081';
    socket = io(baseURL);
  }
};

export const setupSocketListeners = (callback: (data: any) => void, errorCallback: (error: any) => void) => {
  connectSocket();

  socket?.on('connect', () => {
    console.log('Conectado ao servidor de socket.');
  });

  socket?.on('update', (data: any) => {
    console.log('Dados recebidos do servidor:', data);
    callback(data);
  });

  socket?.on('disconnect', () => {
    console.log('Desconectado do servidor de socket.');
  });

  // Adicionar manipuladores de eventos para erros
  socket?.on('connect_error', (error) => {
    console.error('Erro de conexão:', error);
    errorCallback(error);
  });

  socket?.on('connect_timeout', (timeout) => {
    console.error('Timeout de conexão:', timeout);
    errorCallback(timeout);
  });
  
};

export default socket;