import styled from 'styled-components';
import { Container, Col } from 'reactstrap';

export const StyledContainer = styled(Container)`
  min-height: 100vh;
  padding-top: 15vh;
`;

export const StyledCol = styled(Col)`
  background-color: #282c34;
  padding: 50px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
`;