import { useContext } from 'react';

import AuthContext from '../context/AuthContext';

function useAuth() {
  
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('App or Routes (<...>) must be envolved with AuthProvider');
  }

  return context;
}

export default useAuth;