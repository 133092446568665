import React, { useState, useEffect, FormEvent } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Row, Button, Label, Progress, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import swal from 'sweetalert';
import api from '../../services/api';
import { v4 as uuidv4 } from 'uuid';

import { StyledContainer, StyledCol } from './styles';
import AnimationContainer from '../../components/Containers/AnimationContainer';

interface AvailableAnswer {
  id: string;
  answer: string;
}

interface ResearchQuestion {
  id: string;
  question: string;
  question_order: number;
  is_multiple: boolean;
  allow_spontaneous_response: boolean;
  availableAnswers: AvailableAnswer[];
}

interface Zone {
  id: string;
  zone_name: string;
  zone_type: string;
}

interface Research {
  id: string;
  city: string;
  uf: string;
  questions: ResearchQuestion[];
  zones: Zone[];
}

interface FormValues {
  questions: Record<string, any>;
}

interface Messages {
  [key: number]: string;
}

interface SelectDataFormat {
  value: string;
  label: string;
}

interface CustomAnswerDataForm {
  id: string;
  answer: string;
  questionId: string;
}

type Interview = {
  researchId: string;
  researchZoneId: string;
  interviewAnswers: InterviewAnswers[];
}

interface InterviewAnswers {
  researchQuestionId: string;
  availableAnswerId: string | string[];
}

const Survey: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState<Research | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [incentiveMessage, setIncentiveMessage] = useState("São apenas algumas perguntinhas 🥹");
  const [started, setStarted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [responses, setResponses] = useState<FormValues>({ questions: {} });
  const [isSaving, setIsSaving] = useState(false);
  const [spontaneousResponsesData, setSpontaneousResponsesData] = useState<CustomAnswerDataForm[] | null>(null);
  const [interviewData, setInterviewData] = useState<Interview | null>(null);
  const [spontaneousResponsesSaved, setSpontaneousResponsesSaved] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utmSource = queryParams.get('utm_source');
  
    if (utmSource !== 'meta') {
      swal("Acesso negado", "Você não está autorizado a acessar esse recurso.", "error");
      navigate('/404');
      return;
    }
    
    navigate(location.pathname, { replace: true });

    const storedSurveyResponses = localStorage.getItem('surveyResponses');
    const surveyResponses = storedSurveyResponses ? JSON.parse(storedSurveyResponses) : {};
  
    if (id && surveyResponses[id]) {
      swal("Acesso negado", "Você já respondeu a esta pesquisa.", "error");
      navigate('/404'); 
      return;
    }
  
    const fetchSurveyData = async () => {
      try {
        const response = await api.get(`auto-interview/${id}`);
        const surveyData = response.data as Research;
        setSurveyData(surveyData);
        setResponses({
          questions: surveyData.questions.reduce((acc, _, index) => ({ ...acc, [`q${index + 1}`]: '' }), {})
        });
      } catch (error) {
        console.error('Error fetching survey data:', error);
        swal("Erro!", "Houve um problema ao carregar os dados da pesquisa. Por favor, tente novamente mais tarde.", "error");
      }
    };
  
    if (id) {
      fetchSurveyData();
    }
  }, [id, location.pathname, navigate]);  
  

  useEffect(() => {
    updateIncentiveMessage(currentQuestionIndex);
  }, [currentQuestionIndex]);

  const updateIncentiveMessage = (index: number) => {
    const messages: Messages = {
      0: "São apenas algumas perguntinhas 🥹",
      1: "Ótimo começo, continue assim!",
      2: "Continue firme!",
      3: "Já? Mantendo o ritmo.",
      4: "Você está indo muito bem!",
      5: "Excelente trabalho.",
      6: "Siga em frente, estamos próximos!",
      7: "Você está cada vez mais perto.",
      8: "Uau, quase lá!",
      9: "Pertinho, finalize com chave de ouro!"
    };

    setIncentiveMessage(messages[index] || messages[0]);

    if (surveyData && index === surveyData.questions.length) {
      setIncentiveMessage("Essa é a última pergunta, juro.");
    }
  };

  const handleFinish = async () => {
    if (!id) {
      swal("Erro!", "ID da pesquisa não encontrado. Por favor, tente novamente.", "error");
      return;
    }

    setIsSaving(true);
  
    if (spontaneousResponsesData) {
      try {
        if (!spontaneousResponsesSaved) {
          await api.post('auto-interview/store-custom-available-answers', spontaneousResponsesData);
          setSpontaneousResponsesSaved(true);
        }
        await api.post('auto-interview/interview', interviewData);
        setIsSaving(false);
        setIsCompleted(true);
        swal("Sucesso!", "Sua entrevista foi enviada com sucesso.", "success");

        const storedSurveyResponses = localStorage.getItem('surveyResponses');
        const surveyResponses = storedSurveyResponses ? JSON.parse(storedSurveyResponses) : {};
        surveyResponses[id] = true;
        localStorage.setItem('surveyResponses', JSON.stringify(surveyResponses));
      } catch (error) {
        setIsSaving(false);
        console.error('Error sending data:', error);
        swal("Erro!", "Houve um problema ao enviar os dados da pesquisa. Por favor, tente novamente.", "error");
      }
      return;
    }
  
    const currentQuestionKey = `questions.q${currentQuestionIndex + 1}`;
    if (!responses.questions[currentQuestionKey] || responses.questions[currentQuestionKey].length === 0) {
      swal("Atenção!", 'Por favor, preencha a resposta antes de concluir.', "warning");
      return;
    }
  
    // Coletando as respostas espontâneas
    const spontaneousResponses: CustomAnswerDataForm[] = [];
    if (surveyData) {
      surveyData.questions.forEach((question, index) => {
        const questionKey = `questions.q${index + 1}`;
        if (question.allow_spontaneous_response && responses.questions[questionKey]) {
          const customAnswerId = uuidv4();
          spontaneousResponses.push({
            id: customAnswerId,
            answer: responses.questions[questionKey],
            questionId: question.id
          });
  
          // Adicionando o ID gerado às respostas da entrevista
          responses.questions[questionKey] = customAnswerId;
        }
      });
    }
    setSpontaneousResponsesData(spontaneousResponses);
  
    // Coletando as respostas da entrevista
    if (surveyData) {
      const interviewAnswers: InterviewAnswers[] = surveyData.questions.map((question, index) => {
        const questionKey = `questions.q${index + 1}`;
        let selectedAnswerId: string | string[] = '';
        if (question.is_multiple) {
          // Para perguntas de múltipla escolha (checkboxes)
          const selectedAnswers = responses.questions[questionKey] || [];
          selectedAnswerId = selectedAnswers.map((answer: string) =>
            question.availableAnswers.find(av => av.answer === answer)?.id || answer
          );
        } else {
          // Para perguntas de escolha única
          selectedAnswerId = question.allow_spontaneous_response
            ? responses.questions[questionKey]
            : question.availableAnswers.find(answer => answer.answer === responses.questions[questionKey])?.id || '';
        }
        return {
          researchQuestionId: question.id,
          availableAnswerId: selectedAnswerId
        };
      });
  
      const interview: Interview = {
        researchId: surveyData.id,
        researchZoneId: responses.questions[`questions.q${surveyData.questions.length + 1}`],
        interviewAnswers
      };
      setInterviewData(interview);
  
      try {
        // Enviando as respostas espontâneas
        await api.post('auto-interview/store-custom-available-answers', spontaneousResponses);
        setSpontaneousResponsesSaved(true);
        // Enviando a entrevista completa
        await api.post('auto-interview/interview', interview);
        setIsSaving(false);
        setIsCompleted(true);
        swal("Sucesso!", "Sua entrevista foi enviada com sucesso.", "success");

        const storedSurveyResponses = localStorage.getItem('surveyResponses');
        const surveyResponses = storedSurveyResponses ? JSON.parse(storedSurveyResponses) : {};
        surveyResponses[id] = true;
        localStorage.setItem('surveyResponses', JSON.stringify(surveyResponses));
        
      } catch (error) {
        setIsSaving(false);
        console.error('Error sending data:', error);
        swal("Erro!", "Houve um problema ao enviar os dados da pesquisa. Por favor, tente novamente.", "error");
      }
    }
  };  

  const handleNext = () => {
    const currentQuestionKey = `questions.q${currentQuestionIndex + 1}`;
    if (!responses.questions[currentQuestionKey] || responses.questions[currentQuestionKey].length === 0) {
      swal("Atenção!", 'Por favor, preencha a resposta antes de concluir.', "warning");
      return;
    }
    if (surveyData && isLastQuestion) {
      handleFinish();
    } else {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (!isFirstQuestion) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    const questionKey = `questions.q${currentQuestionIndex + 1}`;

    setResponses(prevResponses => {
      const newResponses = { ...prevResponses.questions };

      if (type === 'checkbox') {
        const valueArray = newResponses[questionKey] || [];
        newResponses[questionKey] = checked 
          ? [...valueArray, value]
          : valueArray.filter((val: string) => val !== value);
      } else {
        newResponses[questionKey] = value;
      }

      return { ...prevResponses, questions: newResponses };
    });
  };

  const handleSelectChange = (selectedOption: SelectDataFormat | null) => {
    if (!surveyData) {
      return;
    }
    const questionKey = `questions.q${surveyData?.questions.length + 1}`;
    setResponses(prevResponses => ({
      ...prevResponses,
      questions: {
        ...prevResponses.questions,
        [questionKey]: selectedOption ? selectedOption.value : ''
      }
    }));
  };

  const renderQuestionComponent = (question: ResearchQuestion, index: number) => {
    if (!question || !question.availableAnswers) {
      return null;
    }
  
    const questionKey = `questions.q${index + 1}`;
    const renderInput = (answer: string, type: 'checkbox' | 'radio', idx: number) => (
      <FormGroup check key={idx}>
        <Label check className="text-white">
          <Input
            type={type}
            name={questionKey}
            value={answer}
            checked={type === 'checkbox'
              ? (responses.questions[questionKey] || []).includes(answer)
              : responses.questions[questionKey] === answer}
            onChange={handleChange}
          />
          {answer}
        </Label>
      </FormGroup>
    );
  
    return (
      <FormGroup>
        {question.availableAnswers.map((answer, idx) => renderInput(answer.answer, question.is_multiple ? 'checkbox' : 'radio', idx))}
        {question.allow_spontaneous_response && (
          <Input
            type="text"
            name={questionKey}
            value={responses.questions[questionKey] || ''}
            onChange={handleChange}
            placeholder="Sua resposta"
          />
        )}
      </FormGroup>
    );
  };  

  const renderLastQuestion = () => {
    if (!surveyData) {
      return;
    }
    const questionKey = `questions.q${surveyData?.questions.length + 1}`;
    const zoneOptions = surveyData?.zones.map(zone => ({
      value: zone.id,
      label: `${zone.zone_name} - zona ${zone.zone_type}`
    })) || [];

    return (
      <FormGroup>
        <Label className="text-white">Qual é o nome do bairro ou povoado que você mora? Sua resposta é anônima.</Label>
        <Select
          options={zoneOptions}
          onChange={handleSelectChange}
          value={zoneOptions.find(option => option.value === responses.questions[questionKey]) || null}
          placeholder="Selecione seu bairro ou povoado"
        />
      </FormGroup>
    );
  };

  if (!surveyData) {
    return (
      <StyledContainer className="bg-purple" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h4 className="text-white mb-4">Carregando pesquisa...</h4>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }

  if (!started) {
    return (
      <StyledContainer className="bg-purple" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h4 className="text-white mb-4">Olá! Essa é uma Pesquisa de Opinião Pública 🔎</h4>
            <div className="d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                Sua participação é <strong>anônima</strong>🕵️ e nós:
                <p></p>
                <ul>
                  <li><strong>Não</strong> solicitamos <strong>nenhuma informação pessoal sua</strong> 🚫.</li>
                  <br />
                  <li><strong>Não</strong> estamos vinculados a <strong>nenhuma campanha</strong> ou <strong>partido político</strong> 🚫.</li>
                </ul>
              </p>
            </div>
            <div className="d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>A pesquisa dura <strong>menos que um minuto!</strong> 🚀</p>
            </div>
            <br />
            <div className="d-flex justify-content-center justify-content-sm-center">
              <Button color="primary" onClick={() => setStarted(true)} className="mr-2">Entendi 😊</Button>
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }

  if (isSaving) {
    return <AnimationContainer animationType='loadingDots' visible={true}/>;
  } 

  if (isCompleted) {
    return (
      <StyledContainer className="bg-purple" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h4 className="text-center text-white mb-4">Obrigado pela sua participação 😊</h4>
            <div className="d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                Sua participação foi muito importante para nossa pesquisa.
              </p>
            </div>
            <div className="d-flex text-white">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                Obrigado!
              </p>
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }

  const totalQuestions = surveyData.questions.length + 1; // Adding one for the zone question
  const progressPercent = (currentQuestionIndex / totalQuestions) * 100;
  const isLastQuestion = currentQuestionIndex === surveyData.questions.length;
  const isFirstQuestion = currentQuestionIndex === 0;

  const progressStyle = {
    width: '100%',
    height: '5px',
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(to right, red, green ${progressPercent}%, transparent ${progressPercent}%)`,
    backgroundSize: `${progressPercent}% 100%`,
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.5s ease'
  };

  return (
    <StyledContainer className="bg-purple" fluid>
      <Row className="justify-content-center ml-2 mr-2">
        <StyledCol md={8} lg={8}>
          <h4 className="text-white mb-4">Por favor, responda:</h4>
          <form onSubmit={(e: FormEvent) => { e.preventDefault(); handleNext(); }}>
            <div className="mb-4">
              <Label htmlFor={`q${currentQuestionIndex + 1}`} className="d-block mb-2">
                <h5 className="text-white"><strong><i>{isLastQuestion ? 'Qual é o nome do bairro ou povoado que você mora? Sua resposta é anônima.' : surveyData.questions[currentQuestionIndex].question}</i> 🤔</strong></h5>
              </Label>
              <div className="mt-6">
                {isLastQuestion ? renderLastQuestion() : renderQuestionComponent(surveyData.questions[currentQuestionIndex], currentQuestionIndex)}
              </div>
              <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                <Progress bar style={progressStyle} value="100" max="100" />
              </div>
            </div>
            <div className="mb-4 d-flex align-items-center justify-content-center">
              <span className="text-muted small flex-shrink-0 mr-3">{incentiveMessage}</span>
            </div>
            <div className="d-flex justify-content-end justify-content-sm-center">
              <Button color="warning" onClick={handlePrevious} disabled={isFirstQuestion} className="mr-2">Anterior</Button>
              <Button type="submit" color="success" className="ml-2">{isLastQuestion ? 'Concluir' : 'Próxima'}</Button>
            </div>
          </form>
        </StyledCol>
      </Row>
    </StyledContainer>
  );
};

export default Survey;