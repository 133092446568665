import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Button } from 'reactstrap';
import Lottie from 'react-lottie';

import animation404 from '../../../assets/lottie/error-404.json';

const Page404 = () => {

  const navigate = useNavigate();

  function handleTakeMe() {
    navigate('/');
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation404,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <React.Fragment>

      <Container>
        <Row className="text-center">
          <div className="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main">
            <Row>
              <div className="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                <h3>Página não encontrada</h3>
                <p>A página que você procura não foi encontrada.</p>
                <Row>
                  <Lottie options={ lottieOptions } />
                </Row>
                <Button onClick={handleTakeMe} className="btn-icon btn-2 mt-3" color="default" type="button">
                  Leve-me para a página inicial
                </Button>
              </div>
            </Row>
          </div>
        </Row>
      </Container>
      
    </React.Fragment>
  )
}

export default Page404;