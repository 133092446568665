import styled, { createGlobalStyle } from 'styled-components';
import { Label, Modal } from 'reactstrap';
import MUIDataTable from 'mui-datatables';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #212529;
  }
`;

export const ZoneContainer = styled.div`
  margin-bottom: 0px;
`;

export const CustomLabel = styled(Label)`
  margin-bottom: 0px;
`;

export const QuestionTitle = styled(Label)`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
`;

export const QuestionTitleBold = styled(Label)`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`;

export const MiniModal = styled(Modal)`
  max-width: 400px;
`;

export const StyledDataTable = styled(MUIDataTable)`
  &.MuiPaper-root {
    background-color: #333; // Cor de fundo do papel
    color: white; // Cor do texto geral
    box-shadow: none; // Remova a sombra se necessário

    & .MuiInputBase-input {
      color: white; // Ajuste para a cor do texto na caixa de busca
    }

    & .MuiToolbar-root {
      background-color: #212121; // Cor de fundo da barra de ferramentas
      color: white; // Cor do texto na barra de ferramentas

      & .MuiTypography-h6 {
        color: white; // Cor do título
      }

      & .MuiSvgIcon-root {
        color: white; // Cor dos ícones
      }
    }

    & .MuiTableHead-root {
      background-color: #424242; // Cor de fundo do cabeçalho
      & .MuiTableCell-head {
        color: white; // Cor do texto do cabeçalho
        & .MuiTableSortLabel-root {
          color: white; // Cor do texto das labels de ordenação
          &:hover {
            color: #bbb; // Cor ao passar o mouse
          }
          &.MuiTableSortLabel-active {
            color: #fff; // Cor quando ativo
            & .MuiSvgIcon-root {
              color: white; // Cor dos ícones de ordenação
            }
          }
        }
      }
    }

    & .MuiTableCell-root {
      color: white; // Cor do texto das células
      border-bottom: 1px solid #424242; // Cor das linhas entre os registros
    }

    & .MuiTablePagination-root {
      background-color: #424242; // Cor de fundo da paginação
      color: white; // Cor do texto da paginação
    }

    // Adicione o seguinte para estilizar a área de título da tabela
    & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiButtonBase-root {
      color: white;
    }
    
    // Estilizando a área da célula do cabeçalho
    & .MuiTableCell-head {
      background-color: #424242; // Para garantir que a cor de fundo do cabeçalho seja uniforme
    }
  }
`;