import styled from 'styled-components';
import { Card } from 'reactstrap';

export const TaskNumber = styled.div`
  cursor: pointer;
`;

export const CustomCard = styled(Card)`
  background-color: #333;
  color: #fff;
`;