import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface VennDiagramProps {
  percentageOfAInA: number;
  percentageOfBInB: number;
  percentageOfBoth: number;
  percentageOfAGivenA: number;
  totalA: number;
  totalB: number;
  occurrences: number;
  questionA: string;
  answerA: string;
  questionB: string;
  answerB: string;
  width: number;
  height: number;
}

const VennDiagram: React.FC<VennDiagramProps> = ({
  percentageOfAInA,
  percentageOfBInB,
  percentageOfBoth,
  percentageOfAGivenA,
  totalA,
  totalB,
  occurrences,
  questionA,
  answerA,
  questionB,
  answerB,
  width,
  height,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const scale = d3.scaleSqrt()
      .domain([0, Math.max(totalA, totalB)])
      .range([0, width / 5]);

    const radiusA = scale(totalA);
    const radiusB = scale(totalB);

    const positions = {
      A: { cx: width / 2 - radiusB / 2, cy: height / 2 },
      B: { cx: width / 2 + radiusA / 2, cy: height / 2 },
    };
    
    const drawCircleAndText = (key: keyof typeof positions, percentage: number) => {
      const pos = positions[key];
      const radius = key === 'A' ? radiusA : radiusB;

      svg.append('circle')
        .attr('cx', pos.cx)
        .attr('cy', pos.cy)
        .attr('r', radius)
        .style('fill', key === 'A' ? '#a600c3' : '#cf6100')
        .style('fill-opacity', 0.5)

      const textX = key === 'A' ? pos.cx - radius : pos.cx + radius;
      const textAnchor = key === 'A' ? 'start' : 'end';

      const textElement = svg.append('text')
        .attr('x', textX)
        .attr('y', pos.cy)
        .style('fill', 'white')
        .style('font-size', '12px')
        .attr('text-anchor', textAnchor);

      textElement.append("tspan")
        .attr('x', textX)
        .attr('dy', '0em')
        .style('font-weight', 'bold')
        .text(`${percentage}%`);

      textElement.append("tspan")
        .attr('x', textX)
        .attr('dy', '0.8em')
        .text('da amostra');
    };

    drawCircleAndText('A', percentageOfAInA);
    drawCircleAndText('B', percentageOfBInB);

    const intersectionCenterX = (positions.A.cx + positions.B.cx) / 2;

    const textElement = svg.append('text')
      .attr('x', intersectionCenterX)
      .attr('y', height / 2)
      .attr('dy', '0.35em')
      .style('fill', 'white')
      .style('font-size', '10px')
      .attr('text-anchor', 'middle')
      .attr('transform', `rotate(90, ${intersectionCenterX}, ${height / 2})`)
      .style('font-weight', 'bold')
      .text(`${percentageOfAGivenA}%`);

    textElement.append("tspan")
      .style('font-weight', 'normal')
      .text(' de A em B');

    textElement.append("tspan")
      .attr('dy', '1.0em')
      .attr('x', intersectionCenterX)
      .style('font-weight', 'bold')
      .text(`(${percentageOfBoth}%)`);

      textElement.append("tspan")
      .style('font-weight', 'normal')
      .text(' do total');

  }, [totalA, totalB, occurrences, questionA, answerA, questionB, answerB, width, height]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};

export default VennDiagram;
