import styled, { createGlobalStyle } from 'styled-components';
import { Container, Button, Card, CardBody, CardTitle, CardText, Nav, NavItem, NavLink } from 'reactstrap';
import Select, { Props as SelectProps } from 'react-select';

export interface OptionType {
  label: string;
  value: string;
}

const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#495057',
    borderColor: state.isFocused ? '#973fd4' : '#ced4da',
    '&:hover': {
      borderColor: state.isFocused ? '#973fd4' : '#ced4da',
    },
    boxShadow: state.isFocused ? '0 0 0 .25rem rgba(255, 7, 222, 0.5)' : 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#495057',
    color: '#f8f9fa',
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: '#495057',
    color: '#f8f9fa',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#973fd4'
      : state.isFocused
      ? 'rgba(255, 193, 7, 0.1)'
      : undefined,
    color: '#f8f9fa',
    cursor: 'pointer',
    ':active': {
      backgroundColor: state.isSelected
        ? '#973fd4'
        : 'rgba(255, 193, 7, 0.1)',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#f8f9fa',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#f8f9fa',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#adb5bd',
  }),
};

export const DarkNav = styled(Nav)`
  background-color: #343a40;
  border-bottom: 1px solid #495057;
`;

export const DarkNavItem = styled(NavItem)``;

export const DarkNavLink = styled(NavLink)`
  color: #adb5bd !important; 
  background-color: #343a40 !important;

  &:hover {
    color: #ffffff !important;
    background-color: #495057 !important;
  }

  &.active {
    color: #ffffff !important;
    background-color: #3d4852 !important;
    border-color: #495057 !important;
  }
`;


export const DarkSelect: React.FC<SelectProps<OptionType, false>> = (props) => {
  return (
    <Select<OptionType, false>
      styles={{
        ...customSelectStyles,
        control: (base, state) => ({
          ...customSelectStyles.control(base, state),
          width: '100%', // Assegura que o Select ocupe todo o espaço disponível
        }),
      }}
      isMulti={false}
      {...props}
    />
  );
};

export const PurpleButton = styled(Button)`
  background-color: #973fd4;
  border-color: #973fd4;
  color: #000; 

  &:hover {
    background-color: #7c00d4; 
    border-color: #7c00d4;
  }
`;

export const HeaderContainer = styled(Container)`
  margin-bottom: 20px;
  background: linear-gradient(35deg, #5603ad 0, #9d03ad 100%) !important;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  &:hover, &:focus {
    background-color: #0a0c0d !important;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #973fd4;
  border-color: #973fd4;
  color: #ffffff; 

  &:hover {
    background-color: #7c00d4; 
    border-color: #7c00d4;
  }

  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 10px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #212529;
  }
`;

export const StyledCard = styled(Card)`
  background-color: #333;
  color: #fff;
  margin-bottom: 20px;
`;

export const StyledCardBody = styled(CardBody)`
  background-color: #444;
  padding: 8px 12px;
`;

export const StyledCardText = styled(CardText)`
  color: #ccc;
  margin-bottom: 4px;
`;


export const StyledCardTitle = styled(CardTitle)`
  color: #ddd;
`;

export const StyledHeading = styled.h2`
  color: #fff;
`;