import styled from 'styled-components';
import { Button, Input, InputGroupText } from 'reactstrap';

export const PurpleButton = styled(Button)`
  background-color: #973fd4;
  border-color: #973fd4;
  color: #fff;

  &:hover {
    background-color: #7c00d4;
    border-color: #7c00d4;
  }
`;

export const DarkInputGroupText = styled(InputGroupText)`
  background-color: #505050;
  border: 1px solid #636363;
  color: white;
`;

export const DarkInput = styled(Input)`
  background-color: #505050;
  border: 1px solid #636363;
  color: white;

  ::placeholder {
    color: #ccc;
  }

  &:focus {
    background-color: #505050;
    border: 1px solid #636363;
    box-shadow: none;
    color: white;
  }

  &:not(:focus) {
    background-color: #505050;
    color: white;
  }
`;