import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  UncontrolledCollapse,
  NavbarBrand,
  Nav,
  Row,
  Col,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Tooltip
} from "reactstrap";

import useAuth from '../../../hooks/useAuth';

import { CustomNavbar } from './styles';

import { GoTasklist } from 'react-icons/go';
import { FiUsers } from 'react-icons/fi';
import { BsClipboardData } from 'react-icons/bs';
import { BiFullscreen } from 'react-icons/bi';

import logoDefault from '../../../assets/img/logo/logo-publisol-default.png';
import logoPulisolPesquisasV1 from '../../../assets/img/logo/logo-publisol-pesquisas-v1.png';

const DefaultNavbar = () => {

  const navigate = useNavigate();

  const { signOut, user, profilePicBase64 } = useAuth();

  const [tooltipResearchesMenuOpen, setTooltipResearchesMenuOpen] = useState(false);
  const [tooltipResultsMenuOpen, setTooltipResultsMenuOpen] = useState(false);
  const [tooltipResearchAgentsMenuOpen, setTooltipResearchAgentsMenuOpen] = useState(false);

  const toggleTooltip = (menuItem: string) => {

    switch (menuItem) {
      case 'researches':
        setTooltipResearchesMenuOpen(!tooltipResearchesMenuOpen);
        break;
      case 'results':
        setTooltipResultsMenuOpen(!tooltipResultsMenuOpen);
        break;
      case 'researchAgents':
        setTooltipResearchAgentsMenuOpen(!tooltipResearchAgentsMenuOpen);
        break;
      default:
        break;
    }
  }

  const requestFullScreen = () => {
    if (document.fullscreenElement){
      document.exitFullscreen();
      return;
    }
    document.documentElement.requestFullscreen();
  }

  return (
    <React.Fragment>
      <CustomNavbar
        className="shadow navbar-horizontal navbar-dark bg-gradient-indigo"
        expand="lg"
      >

        <NavbarBrand href="#" onClick={e => e.preventDefault()}>
          <img alt="Logo" src={logoPulisolPesquisasV1} />
        </NavbarBrand>
        <button
          aria-controls="navbar-default"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navbar-default"
          data-toggle="collapse"
          id="navbar-default"
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <UncontrolledCollapse navbar toggler="#navbar-default">
          
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img
                    alt="logo"
                    src={logoDefault}
                  />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  aria-controls="navbar-default"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navbar-default"
                  data-toggle="collapse"
                  id="navbar-default"
                  type="button"
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-lg-auto" navbar>

            <UncontrolledDropdown nav inNavbar id="TooltipResearchesMenu">
              <DropdownToggle
                onClick={() => { navigate('/pesquisas') }} 
                className="text-white"
                nav
              >
                <GoTasklist/> <b>Pesquisas</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipResearchesMenuOpen} target="TooltipResearchesMenu" toggle={() => toggleTooltip('researches')}>
              Gerenciar pesquisas existentes e cadastrar novas pesquisas.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar id="TooltipResearchAgentsMenu">
              <DropdownToggle
                onClick={() => { navigate('/agentes-de-pesquisas') }} 
                className="text-white"
                nav
              >
                <FiUsers/> <b>Agentes</b>
              </DropdownToggle>
            </UncontrolledDropdown>
            <Tooltip placement="right" isOpen={tooltipResearchAgentsMenuOpen} target="TooltipResearchAgentsMenu" toggle={() => toggleTooltip('researchAgents')}>
              Gerir agentes de campo que realizam as pesquisas.
            </Tooltip>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                onClick={requestFullScreen} 
                className="text-white"
                nav
              >
                <BiFullscreen/>
              </DropdownToggle>
            </UncontrolledDropdown>
            
          </Nav>

          <Nav className="align-items-left d-md-flex" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="text-white" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="Minha foto de perfil"
                      src={ profilePicBase64.source }
                    />
                  </span>
                  <Media className="ml-2 d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      <b>{ user.show_name }</b>
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => { navigate('/meu-perfil') }}>
                  Meu Perfil
                </DropdownItem>
                {
                  user.is_admin
                  ? <DropdownItem onClick={() => { navigate('/usuarios') }}>
                      Gerenciar usuários
                    </DropdownItem>
                  : null
                }
                <DropdownItem divider />
                <DropdownItem onClick={ () => signOut(navigate) } >
                  Desconectar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

        </UncontrolledCollapse>

      </CustomNavbar>
    </React.Fragment>
  );
}

export default DefaultNavbar;