import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { DarkBackground } from './styles';

import loadingPaperPlane from '../../../assets/lottie/loading-paperplane.json';
import comingSoon from '../../../assets/lottie/coming-soon.json';
import error404 from '../../../assets/lottie/error-404.json';
import loadingDots from '../../../assets/lottie/loading-dots.json';
import ai from '../../../assets/lottie/ai.json';
import aiV2 from '../../../assets/lottie/ai-v2.json';

type AnimationType = 'dataTransfer' | 'comingSoon' | 'error404' | 'loadingDots' | 'AI' | 'AIv2';


interface IProps {
  animationType: AnimationType;
  visible: boolean;
}

const AnimationContainer = (props: IProps) => {

  const [className, setClassName] = useState('d-none');

  useEffect(() => {
    props.visible ? setClassName('') : setClassName('d-none');
  }, [props.visible]);

  const setLottieFile = (animationType: string) => {
    let lottieFile;
    switch (animationType) {
      case 'dataTransfer':
        lottieFile = loadingPaperPlane;
        break;
      case 'comingSoon':
        lottieFile = comingSoon;
        break;
      case 'error404':
        lottieFile = error404;
        break;
      case 'loadingDots':
        lottieFile = loadingDots;
        break;
      case 'AI':
        lottieFile = ai;
        break;
      case 'AIv2':
        lottieFile = aiV2;
        break;
      default:
        lottieFile = loadingPaperPlane;
        break;
    }
    return lottieFile;
  }

  const lottieFile = setLottieFile(props.animationType);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <DarkBackground className={className}>
      <Lottie options={ lottieOptions } height={300} width={300} />
    </DarkBackground>
  );
}

export default AnimationContainer;