import React from 'react';
import { StyledBadge } from './styles';

interface LegendItemProps {
  color: string;
  text: React.ReactNode;
}

const LegendItem: React.FC<LegendItemProps> = ({ color, text }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <StyledBadge color={color} />
      <span className="ml-2" style={{ color: 'white' }}>{text}</span>
    </div>
  );
};

interface LegendProps {
  items: LegendItemProps[];
}

const DataLegend: React.FC<LegendProps> = ({ items }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      {items.map((item, index) => (
        <LegendItem key={index} color={item.color} text={item.text} />
      ))}
    </div>
  );
};

export default DataLegend;